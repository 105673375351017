<template>
  <div>
    <notification-panel-header :notification="notification">
    </notification-panel-header>
  </div>
</template>

<script>
import notificationPanelHeader from './notification-components/notification-panel-header'

export default {
  name: "notification-ValidationFlowPending",

  props: {
    notification: { type: Object, required: true }
  },

  components: {
    notificationPanelHeader
  },

}
</script>

<style scoped></style>
