<template>
  <div>
    <!-- Ocultamos botton añadir nuevo pictograma desde este apartado por que tiene su propio apartado luego
    si es necesario eliminar todo lo que no es necesario hasta el componente -->
    <!-- <div class="top-bar flex justify-end">
      <v-button
        @click="addPictogram"
        icon="plus"
        class="ml-4"
        :disabled="showFormImportEntities || showFormLink"
      >{{ $t('add_pictogram') }}</v-button>
    </div> -->

    <div class="flex">
      <div :class="[( showFormData || showFormImportEntities || showFormLink || onShowLinkedActivities ? 'w-7/12' : 'w-full'), 'mr-2']">
        <pictograms-list ref="list_pictograms"
          :entity-id="projectId"
          :permittedActions="permittedActions"
          :showDownloadFileOriginal="showDownloadFileOriginal"
          :disabled-menu-actions="showFormImportEntities || showFormData || showFormLink"
          @epc-preview="handleOnPreviewWithKonva"
          @epc-openstatuses="handleOpenStatuses"
          @epc-openscheduleddates="handleOpenScheduledDates"
          @epc-openlink="handleLinkPictogram"
          @epc-openlinked="handleLinkedEntities"
          @epc-openinfo="handleInfo"
        ></pictograms-list>
      </div>

      <transition name="fade">
        <div class="w-5/12 ml-2" v-if="showFormData">
          <pictogram-add ref="refAddPictogram"
            :entity-id="projectId"
            @epc-preview="handleOnPreview"
            @epc-close="handleOnCloseEdit"
            @reload="handleSuccess"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showConfigStatuses">
          <pictogram-statuses
            :entity-id="projectId"
            :pictogramId="pictogramId"
            :layers="pictogram_layers"
            @epc-close="(event) => { showConfigStatuses = !event }"
            @reload="handleSuccess"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showConfigScheduledDates">
          <pictogram-scheduled-dates
            :entity-id="projectId"
            :pictogramId="pictogramId"
            :layers="pictogram_layers"
            @epc-close="(event) => { showConfigScheduledDates = !event }"
            @reload="handleSuccess"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="showFormLink">
          <pictogram-link
            :project-id="projectId"
            :pictogramsToLink="pictogramsToLink"
            @epc-close="handleOnCloseLinkGroup"
          />
        </div>

        <div class="w-5/12 ml-2" v-if="onShowLinkedActivities">
          <linked-entities
            :project-id="projectId"
            :entity="pictogram"
            :entityType="'pictogram'"
            :pictogramsToLink="pictogramsToLink"
            @epc-close="(e) => { onShowLinkedActivities = !e }"
          />
        </div>

        <!-- <div class="w-5/12 ml-2" v-if="showFormImportEntities">
          <chapters-import
            :projectId="projectId"
            :entity-type="'chapters'"
            :morph-type="entityType"
            @epc-close="(event) => { showFormImportEntities = false }"
            @epc-success-import="() => { showFormImportEntities = false ; $refs.list_chapters.reload() }"
          />
        </div> -->

      </transition>
    </div>

    <konva-preview :ref="'konva_preview_modal'"
      :canActionsPictogram="_getCanActionsPictograms()"
      @epc-screenshot="_GetScreenshot"/>

  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import PictogramsList from './pictograms-list.vue';
import PictogramAdd from './pictogram-add.vue';
import PictogramStatuses from './pictogram-statuses.vue';
import PictogramScheduledDates from './pictogram-scheduled-dates.vue';
import PictogramLink from './pictogram-link.vue';
import LinkedEntities from './linked-entities.vue';
import KonvaPreview from './viewer-konva/konva-preview-modal.vue';

export default {
  name: "manage-pictograms",

  components: {
    PictogramsList,
    PictogramAdd,
    PictogramStatuses,
    PictogramScheduledDates,
    PictogramLink,
    KonvaPreview,
    LinkedEntities
  },

  data: () => ({
    group: null, // objeto que se crea
    showFormData: false,
    showFormImportEntities: false,
    showFormLink: false,
    onShowLinkedActivities: false, // abre form de entidades vinculadas al pictograma
    showConfigStatuses: false, // abre form para configurar los distintos estados del pictograma
    showConfigScheduledDates: false, // abre form para configurar las fechas programadas del pictograma

    isPreview: false,
    pictogram: null,
    pictogramId: 0,
    pictogram_layers: [],

    pictogramsToLink: [] // usado cuando se vincularan pictogramas o pictograma a una actividad
  }),

  props: {
    projectId: { type: Number, required: true },
    showDownloadFileOriginal: { type: Boolean, default: true },
    permittedActions: { type:Object, default() { return {} } }, // acciones que se mostraran en la columna acciones por defecto todas
    canActionsPictogram: { type:Object, default() { return {} } } // acciones que se puede realizar sobre el pictograma por defecto ninguna
  },

  computed: {

  },

  created() {
  },

  mounted() {
  },

  methods: {
    RefreshListPictograms() {
      this.$refs.list_pictograms.reload()
    },

    addPictogram() {
      this.chapter = null
      this.showFormData = true;
    },

    handleOnCloseEdit(event) {
      this.showFormData = !event
    },

    handleOnCloseLinkGroup(event) {
      this.showFormLink = !event
    },

    handleSuccess(entity) { // recibe la entidad editada
      this.showFormData = false,
      this.showConfigStatuses = false,
      this.showFormLink = false,
      this.$refs.list_pictograms.reload()
    },

    // handleEditEntity(entity) {
    //   this.entity = entity
    //   this.showFormData = true;
    // },

    handleAddPictogram(chapter) {
      this.chapter = chapter
      this.showFormData = true;
    },

    handleEditPictogram(chapter) {
      this.chapter = chapter
      this.showFormData = true;
    },

    handleLinkPictogram(pics) {
      this.pictogramsToLink = pics
      this.showFormLink = pics.length > 0
    },

    async handleInfo(pictogram) {

      let url = `/api/v2/pictogram/${pictogram.id}/create-entities`;

      const { data } = await axios.post(url).catch((error) => { console.log(error) })

      if (data && data.success) {

        this.$notify.success('save_successful')

        if( data.warning )
          this.$notify.warning(data.warning)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error('assignment_change_fail')
      }
    },

    handleOnPreview(e) {
      // this.$refs.pictogram_preview_modal.show(e)
      this.$refs.konva_preview_modal.show(e)
      this.isPreview = e.preview ?? false
    },

    handleOnPreviewWithKonva(e) {
      this.$refs.konva_preview_modal.show(e)
    },

    handleOpenStatuses(e) {
      this.pictogramId = e.id
      this.pictogram_layers = e.layers === null && typeof e.layers === 'undefined' ? [] : e.layers
      this.showConfigStatuses = true
    },

    handleOpenScheduledDates(e) {
      this.pictogramId = e.id
      this.pictogram_layers = e.layers === null && typeof e.layers === 'undefined' ? [] : e.layers
      this.showConfigScheduledDates = true
    },

    handleLinkedEntities(e) {
      this.pictogram = e
      this.onShowLinkedActivities = true
    },

    _getCanActionsPictograms() {
      if (this.isPreview) {
        return {
          'save': true,
          'select_entity': false
        }
      }

      return this.canActionsPictogram
    },

    _GetScreenshot(e) {
      console.log('get screenshot save: ', e)
      // TODO:tendriamos que mandar a guardar al comoponente pictogram add
      this.$refs.refAddPictogram.SaveDesignWithImage(e)
      // this.$refs.konva_preview_modal.hide()
    }
  }

};
</script>

<style scoped>

</style>
