<template>
<div>
  <div class="panel p-2">
    <div class="flex items-center justify-between w-full mb-5">
      <div class="flex flex-col">
        <div><label><b>{{ group && group.id ? 'Editar' : 'Crear' }} {{ group && group.parent_id ? 'subgrupo' : 'grupo' }} </b></label></div>
        <div></div>
      </div>
      <div>
        <b-tooltip :label="$t('close')" position="is-left">
          <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
            <b-icon pack="fas" icon="times" size="is-small" />
          </div>
        </b-tooltip>
      </div>
    </div>

    <div class="w-full pb-3">
      <div class="mt-4">
        <b-field :label="$t('groups') + ' padre'">
          <treeselect v-model="groupParent"
            :multiple="false"
            :options="groups"
            :disabled="!form.id && !!form.parent_id"
          />
        </b-field>
      </div>

      <div class="mt-4">
        <l-form-input
          v-model="form.code"
          ref="code"
          :form="form"
          field="code"
          label="code"
        />
      </div>

      <div class="mt-4">
        <l-form-input ref="name"
          :form="form"
          field="name"
          label="name"
        />
      </div>

      <div class="mt-4">
        <label for="weight" class="label">
          {{ $t('weight') }}
        </label>
        <l-form-input
          v-model="form.weight"
          ref="weight"
          :form="form"
          field="weight"
        />
      </div>

      <div class="mt-4">
        <!-- <label for="color" class="label">
          {{ $t('color') }}
        </label>
        <l-form-input
          v-model="form.color"
          ref="color"
          :form="form"
          field="color"
        /> -->
        <b-field label="Select a color">
          <b-colorpicker
            v-model="selectedColor"
            :color-formatter="formatter"
            :alpha="hasAlpha"
            expanded
            position="is-top-right"
          />
        </b-field>

      </div>

    </div>

    <div class="flex items-center justify-end w-full">
      <form @submit.prevent="sendGroupData" @keydown="form.onKeydown($event)" v-if="!processingData"
        class="w-full flex items-center justify-end mb-2 mt-2">
        <v-button :disabled="disableSaveButton" type="submit" icon="thumbs-up" icon-pack="far" class="mr-4">{{ $t('save') }}</v-button>
        <v-button color="grey" icon="undo-alt" @click="onCloseClicked">{{ $t('cancel') }}</v-button>
      </form>

      <v-button-processing v-if="processingData" />
    </div>
  </div>
</div>
</template>

<script>
// import Color from '../../../../../src/utils/color'
import Color from 'buefy/src/utils/color'

import Form from "vform";
import axios from "axios";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "activity-group-edit",

  components: {
    Color,
    Treeselect
  },

  props: {
    entityType: { type: String, required: true },
    entityId: { type: Number, required: true },
    group: { type: Object, default: null },
  },

  data() {
    return {
      disableSaveButton: false,
      processingData: false,

      form: new Form({  // data activity or subactivity of template project
        id: this.group ? this.group.id : null,
        entity_type: this.entityType,
        parent_id: this.group ? this.group.parent_id : null,
        code: this.group ? this.group.code : '',
        name: this.group ? this.group.name : '',
        weight: this.group ? this.group.weight : null,
        // configs: this.group ? this.group.configs : {},
        color: this.group && this.group.color ? this.group.color : null,
      }),

      format: 'hex',
      selectedColor: Color.parse(this.group && this.group.color ? this.group.color : null),
      groups: [], // grupos de actividades
      groupParent: this.group ? this.group.parent_id : null, // grupo de actividad seleccionada

    }
  },

  computed: {
    hasAlpha() {
      return /a\s*$/i.test(this.format)
    }
  },

  watch: {
  },
  created() {
    this.getGroupsList()
  },
  mounted() {

  },

  methods: {
    async getGroupsList() {
      let params = {
        template: 'select'
      };

      const { data } = await axios.get(
        "/api/v2/project/" + this.entityId + "/activity/groups",
        { params: params }
      );

      if (data && data.success) {
        this.groups = data.groups;
      } else {
        this.$notify.error("error_loading_extra_fields_data");
      }
    },

    async getGroupsData() {
      const { data } = await axios.get('/api/v2/activity/groups/' + this.form.id, {params: { with_statuses: 1 }})
      if (data && data.success) {
        this.form.id = data.data.id
        this.form.parent_measurement_id = data.data.parent_measurement_id
        this.form.unit_id = data.data.unit_id
        this.form.code = data.data.code
        this.form.name = data.data.name
        this.form.weight = data.data.weight

      } else {
        this.$notify.error('error_loading_measurement_data')
      }
    },

    async sendGroupData() {
      this.processingData = true

      var url = `/api/v2/project/${this.entityId}/activity/groups`;
      let isCreate = ! this.form.id // si id es null o cero es crear el grupo
      if( !isCreate )
        url = `/api/v2/activity/groups/${this.form.id}`; // se edita el grupo

      this.form.parent_id = typeof this.groupParent === 'undefined' ? null : this.groupParent
      this.form.color = this.formatter(this.selectedColor) === '#000000' ? null : this.formatter(this.selectedColor)

      const { data } = await this.form.post(url).catch((error) => { this.processingData = false })

      if (data && data.success) {

        this.$notify.success('success_editing')

        if( data.warning )
          this.$notify.warning(data.warning)

        this.processingData = false
        this.$emit('epc-success-creating', this.entity)

      } else {
        this.processingData = false
        if( data && data.message )
          this.$notify.error(data.message)
        else
          this.$notify.error('error_creating_group')
      }
    },

    onCloseClicked() {
      this.measurement = null;

      this.$emit('epc-close', true)
    },

    handleEditEntity(entity) {

    },

    formatter (color) {
      return color.toString(this.format)
    }
  },
};
</script>

