<template>
  <div class="panel w-full">
    <div>
      <div class="flex items-center justify-between w-full">
        <div class="font-semibold text-black">{{ $t('pictogram_statuses') }}</div>
        <div>
          <b-tooltip :label="$t('close')" position="is-left">
            <div class="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-red text-grey-lightest" @click="onCloseClicked">
              <b-icon pack="fas" icon="times" size="is-small" />
            </div>
          </b-tooltip>
        </div>
      </div>

      <div class="mt-3">
        <div class="flex items-center bg-blue-light text-white text-sm font-bold px-4 py-3 rounded" role="alert">
          <svg class="fill-current w-8 h-8 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/></svg>
          <p>Realize los cambios necesarios en los estados de los layers y pulse el boton Guardar.</p>
        </div>
      </div>

      <div class="mt-4">
        <b-collapse v-for="(layer, index) of layers"
          class="card mb-1"
          :class="{'border': isOpen == index, 'border-blue': isOpen == index, 'rounded': isOpen == index}"
          animation="slide"
          :key="'tab_lyr_' + layer.id"
          :open="isOpen == index"
          @open="isOpen = index"
          :aria-id="'contentIdForA11y5-' + index">
          <template #trigger="props">
            <div
              class="card-header"
              role="button"
              :aria-controls="'contentIdForA11y5-' + index"
              :style="{ backgroundColor: props.open ? 'rgb(85, 119, 187)' : 'initial'}"
              :aria-expanded="props.open">
              <p class="card-header-title" :style="{ color: props.open ? '#ffffff !important' : 'hsl(0, 0%, 21%)'}">
                <b-icon pack="fas" :icon="_GetIcon(layer)"></b-icon>  {{ $t(layer.name) }}
              </p>
              <a class="card-header-icon">
                <b-icon
                    :icon="props.open ? 'angle-down' : 'angle-up'" pack="fas">
                </b-icon>
              </a>
            </div>
          </template>

          <div class="card-content">
            <div class="content">
              <pictogram-statuses-list :ref="'refStatuses' + layer.name"
                :statuses="statuses[layer.code]"
                :status-type="layer.code"
                @epc-pictogram-status-edit="_HandleOpenModal"
                @epc-pictogram-status-delete="_HandleDeleteElement"
              />
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              :label="$t('add')"
              type="is-info"
              icon-pack="fas"
              icon-left="plus-circle"
              expanded
              @click="_HandleOpenModal( { type: layer.code, status: null } )"/>

            <!-- <a class="card-footer-item p-2" @click="_HandleOpenModal( { type: layer.code, status: null } )">
              <b-icon pack="fas" icon="plus-circle"></b-icon> {{ $t('add') }}
            </a> -->
            <!-- <a class="card-footer-item" @click="_OnSave"> <b-icon pack="fas" icon="save"></b-icon> {{ $t('save') }}</a> -->
            <!-- <a class="card-footer-item">Delete</a> -->
          </footer>
        </b-collapse>
      </div>

    </div>

    <div class="inline-flex justify-end w-full pb-3 mt-5">
      <v-button-processing v-if="processingData" />
      <b-button v-else
        :label="$t('save')"
        type="is-success"
        icon-pack="fas"
        icon-left="save"
        expanded
        @click="_OnSave" />
    </div>

    <pictogram-status-modal ref="ref_status_modal"
      @epc-add-element="_HandleElement"/>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

import PictogramStatusModal from './pictogram-status-modal.vue'
import PictogramStatusesList from './pictogram-statuses-list.vue'

export default {
name: "pictogram-statuses",

components: {
  ScaleLoader,
  PictogramStatusModal,
  PictogramStatusesList
},

props: {
  entityId: { type: Number, required: true },
  entityType: { type: String, required: false, default: 'project' },
  pictogramId: { type: Number, required: true },
  layers: { type: Array, default() { return [] } }
},

data: () => ({
  id: null,
  isLoading: false,
  data: {},
  // statuses: [], // usar si es que se obtiene desde un acceso remoto
  statuses: {},

  processingData: false,

  isOpen: 0, // que collapse esta abierto
}),

watch: {
},

created() {
  // this._LoadData() // no hace falta

  // Colocamos todos los estados de los diferentes layer manipulados por nosotros en un objeto statuses
  // para poder manipular de manera general he individual
  this.layers.forEach((layer) => {
    this.statuses[layer.code] = layer.statuses
  })
},

mounted() {
},

computed: {
  ...mapGetters({
    project: "app/project",
    company: "app/company",
    user: "auth/user",
  }),

},

methods: {

  // no hace falta por que se esta pasando como props en los layers
  // _LoadData() {
  //   console.log('statuses: ', this.statuses)
  //   if (this.statuses !== null && typeof this.statuses !== 'undefined') {
  //     this.statuses_structure = this.statuses.structure
  //     this.statuses_piling = this.statuses.piling
  //     this.statuses_module = this.statuses.module
  //   }
  // },

  _HandleOpenModal(obj) {
    this.$refs.ref_status_modal.show(obj.type, obj.status)
  },

  _HandleElement(type, status) {
    if ( status.id != null){
      this._EditElement(status, this.statuses[type])
    }
    else
      this._AddElement(status, this.statuses[type])
  },

  _HandleDeleteElement(obj) {
    this._DeleteElement(obj.status, this.statuses[obj.type])
  },

  _DeterminateId(statuses) {
    let id = 0
    if( statuses.length > 0 ) {
      const ids = statuses.map( (objeto) => { return Math.abs(objeto.id) });
      id = Math.abs(Math.max(...ids));  // obtenemos el absoluto por si el maximo es un negativo y no genere una resta posterior
    }

    // Multiplicamos por -1 para identificar desde el frontend y backend que son nuevas inserciones
    // lo ya insertados vendrán con signo positivo
    return (id + 1) * -1
  },

  _AddElement(status, statuses) {
    status.id = this._DeterminateId(statuses)
    statuses.push(status)
  },

  _EditElement(status, statuses) {
    let origin = statuses.find( (el) => el.id == status.id )

    if( origin ) {
      origin.code = status.code
      origin.name = status.name
      origin.color = status.color
      origin.is_completion = status.is_completion
    }
  },

  _DeleteElement(status, statuses) {
    const indice = statuses.findIndex(objeto => objeto.id === status.id);
    if( indice !== -1 ) {
      statuses.splice(indice, 1)
    }
  },

  async _OnSave() {
    this.processingData = true

    let data = {
      statuses: this.statuses
    };

    let url =
      "/api/v2/pictogram/" + this.pictogramId + "/statuses";

    this.processingData = true

    await axios({
      method: "post",
      url: url,
      data: data,
    })
    .then((response) => {
      if( response.data.success ) {

        this.$toast.open({
          message: this.$t("assignment_update_success"),
          type: "is-success",
          position: "is-top-right",
        });

        this.$emit('reload')

        this.onCloseClicked()

      } else {

        this.$toast.open({
          message: response.data.error,
          type: "is-danger",
          position: "is-top-right",
        });
      }

      // this.$emit("reload", { id: this.id, entityId: this.entityId }); //pide a su componente padre que recargue
    })
    .catch((error) => {
      //Se produjo un error
      this.isLoading = false;
      if (error.response && error.response.status === 422) {
        //extraemos el primer error de validación
        error.error_msg =
          error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0];
      }

      // Mostramos el error
      this.$toast.open({
        message: error.error_msg || this.$t("assignment_update_error"),
        type: "is-danger",
        position: "is-top-right",
      });
    });

    this.processingData = false
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  onCloseClicked() {
    this.$emit('epc-close', true)
  },

  _GetIcon(layer) {
    return layer.icon ?? 'layer-group'
  }
},
};
</script>
<style scoped>
  /* Oculta los botones para aumentar los inputs numéricos */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  .epc-label {
    display: initial !important;
    color: unset !important;
  }
</style>
