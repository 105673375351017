<template>
    <div>
        <scale-loader v-show="isLoading" />
        <div v-show="!isLoading" id="gantt-container" ref="ganttContainer" style="height: 82vh; width: 100%;"></div>
    </div>
</template>

<script>
import '@bryntum/gantt/gantt.material.css';
import { Gantt, Toolbar } from '@bryntum/gantt';
import axios from "axios";
import moment from 'moment';
import Vue from "vue";
import { mapGetters } from "vuex";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

Vue.use(moment);

let labels = {
    activity: [
        'activity_status_assigned',
        'activity_status_running',
        'activity_status_paused',
        'activity_status_issue',
        'activity_status_done',
        'activity_status_reopened',
        'activity_status_closed',
        'activity_status_delayed_start'
    ],
    assignment: [
        'ok',
        'pending',
        'rejected'
    ]
}

let colors = {
    activity: [
        // assigned 0
        { normal: "#B8C2CC", light: "#E1E5E8" },
        // running 1
        { normal: "#3490dc", light: "#85B9F2" },
        // paused 2
        { normal: "#F6993F", light: "#FFC28F" },
        // issue 3
        { normal: "#e3342f", light: "#F2807C" },
        // done 4
        { normal: "#6574CD", light: "#A3AFE9" },
        // reopened 5
        { normal: "#9561E2", light: "#C5A4F5" },
        // closed 6
        { normal: "#38C172", light: "#7FDEAA" },
        // delayed_start 7
        { normal: "#fc8a18", light: "#FFBC66" }
    ],
    assignment: [
        // 0 OK
        { normal: "#38C172", light: "#7FDEAA" },
        // 1 PENDING
        { normal: "#F6993F", light: "#FFC28F" },
        // 2 REJECTED
        { normal: "#e3342f", light: "#F2807C" }
    ],
    defaults: {
        scheduled: { normal: "#387bd9", light: "#5393ed" },
        real: { normal: "#6ea8fa", light: "#8fbbf7" },
        inspected: { normal: "#ff5e59", light: "#e3342f" },
        primary: { normal: "#57b", light: "#e4ebf8" }
    }
};


export default {
    name: 'gantt-v2',

    components: {
        ScaleLoader
    },

    data() {
        return {
            data: [],
            dependencies: [],
            baselinesConfig: [],
            params: {
                pg: 1,
                s: 1,
                light: 1,
                vs_gantt: 1
            },
            isWelink: false,
            ganttInstance: null,
            enableCriticalPaths: false,
            enableDependencies: true,
            enableBaselines: false,
            isLoading: true,
            rowHeight: 36,
            barMargin: 7,
            criticalColor: '#cc2d29',
            criticalColorLight: '#E0817E',
            isLoadingChildren: {}, // usaremos esto para saber si un nodo esta cargando sus hijos
        }
    },

    computed: {
        ...mapGetters({
            project: 'app/project',
            company: 'app/company'
        }),
    },

    mounted() {
        this.params.p = this.project.id;
        this.isWelink = this.company.alias == 'welink'
        this.getBaselinesConfig()
        this.getData()
    },
    beforeDestroy() {
        if (this.ganttInstance) {
            this.ganttInstance.destroy();
        }
    },

    methods: {
        // gantt methods
        initGantt() {
            // Si existe una instancia previa, la destruimos
            if (this.ganttInstance) {
                this.ganttInstance.destroy();
            }
            this.ganttInstance = new Gantt({
                appendTo: this.$refs.ganttContainer,
                
                project: {
                    tasksData: this.data,
                    // dependecias se manejan por fuera de las tasks
                    dependencies: this.dependencies,
                    // esto para que no se haga un autocalculo de los padres (podria ser util activarlo en futuro)
                    autoCalculatePercentDoneForParentTasks: false,
                },
                height: '100%', //'calc(100vh - 20px)',
                features: {
                    taskMenu: { disabled: true },
                    taskResize: { disabled: true },
                    dependencies: { allowCreate: false, disabled: !this.enableDependencies },
                    percentBar: { allowResize: false },
                    rowReorder: { disabled: true },
                    timeRanges: {
                        showCurrentTimeLine: { name: this.$t('now')}
                    },
                    criticalPaths: { disabled: !this.enableCriticalPaths },
                    baselines: {
                        disabled: !this.enableBaselines,
                        // barra de progreso de la linea base (de momento solo en welink las lineas bases tienen progreso)
                        renderer: ({ baselineRecord }) => {
                            if (!baselineRecord.data.progress && baselineRecord.data.progress != 0) return '';
                            return [
                                {
                                    tag: 'div',
                                    class: 'baseline-progress-bar',
                                    style: `background: #ffffff54; width: ${baselineRecord.data.progress}%; height: 100%`
                                }
                            ];
                        },
                        // tooltip de la linea base
                        template: ({ baseline }) => {
                            return `
                            <div class="tooltip-container">
                                <div><b>${baseline.task.name} (${baseline.name})</b></div>
                                <div><b>${this.$t('start_date_label')}:</b> ${moment(baseline.startDate).format('DD/MM/YYYY')}</div>
                                <div><b>${this.$t('end_date_label')}:</b> ${moment(baseline.endDate).format('DD/MM/YYYY')}</div>
                                ${baseline.data.progress || baseline.data.progress == 0 ? `<div><b>Progreso:</b> ${baseline.data.progress}%</div>` : ''}
                            </div>
                        `;
                        }
                    },
                    taskTooltip: {
                        // tooltip de las tasks (barra principal)
                        template: ({ taskRecord }) => {
                            return `
                            <div class="tooltip-container">
                                <div><b>${taskRecord.name}</b></div>
                                <div><b>${this.$t('progress')}:</b> ${taskRecord.percentDone}%</div>
                                <div><b>${this.$t('start_date_label')}:</b> ${moment(taskRecord.startDate).format('DD/MM/YYYY')}</div>
                                <div><b>${this.$t('end_date_label')}:</b> ${moment(taskRecord.endDate).format('DD/MM/YYYY')}</div>
                            </div>
                        `;
                        },
                    },
                    cellTooltip: { disabled: false, hideDelay: 0, hoverDelay: 0 },
                    cellEdit: { disabled: true },
                    taskEdit: { disabled: true },
                },
                listeners: {
                },
                // render de la barra principal de las tasks
                taskRenderer({ taskRecord }) {
                    return [
                        {
                            tag: 'div',
                            class: 'b-gantt-name',
                            text: taskRecord.percentDone + '%'
                        }
                    ];
                },
                subGridConfigs: {
                    locked: { flex: 3 }, // panel de la izquierda (lista de tasks)
                    normal: { flex: 7 } // panel de la derecha (barras)
                },
                // esto para que cuando pinche una task te lleve a donde esta en las barras
                scrollTaskIntoViewOnCellClick: true,
                // campos que se muestran en el panel de la izquierda
                columns: {
                    data: [
                        { type: 'tree', field: 'code', text: this.$t('code'), width: 50 },
                        { field: 'name', text: this.$t('name'), autoWidth: true, maxWidth: 400 },
                        {
                            type: 'startdate',
                            field: 'startDate',
                            text: this.$t('start_date_label'),
                            width: 100,
                            renderer: ({ record }) => record.startDate ? moment(record.startDate).format('DD/MM/YYYY') : ''
                        },
                        {
                            type: 'enddate',
                            field: 'endDate',
                            text: this.$t('end_date_label'),
                            width: 100,
                            renderer: ({ record }) => record.endDate ? moment(record.endDate).format('DD/MM/YYYY') : ''
                        },
                        { type: 'duration', field: 'duration', text: this.$t('duration'), width: 100, align: 'center' },
                        {
                            field: 'status',
                            text: this.$t('status'),
                            align: 'center',
                            width: 150,
                            htmlEncode: false,
                            renderer: ({ record }) => {
                                if (record.status === null) return '';
                                return `
                                <span
                                    class="uppercase text-white font-black text-center p-1 rounded"
                                    style="font-size: 9px; background: ${colors[record.type][record.status].normal}"
                                >
                                    ${this.$t(labels[record.type][record.status])}
                                </span>`;
                            }
                        },
                        { type: 'percentdone', text: this.$t('progress'), mode: 'circle', width: 70 },
                        {
                            field: 'user.avatar',
                            text: this.$t('user'),
                            width: 70,
                            align: 'center',
                            htmlEncode: false,
                            renderer: ({ record }) => {
                                if (!record.user) return '';
                                return `<img src="${record.user.avatar}" alt="User Avatar" style="width: 25px; height: 25px; border-radius: 50%;">`;
                            },
                            tooltipRenderer: ({ record }) => record.user?.fullname
                        },
                        // { type: 'check', text: this.$t('critical_path_gantt'), field: 'critical_path', width: 70, readOnly: true },
                    ],
                    // importante este campo para que se pueda customizar que campo tendra el icon de desplegar
                    autoTree: false
                },
                // botones superiores
                tbar: new Toolbar({
                    items: [
                        {
                            type: 'buttonGroup',
                            items: [
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-cogs',
                                    cls: 'button button-primary',
                                    text: this.$t('configurations'),
                                    onToggle: this.onSettingsShow,
                                    menu: [
                                        {
                                            type: 'slider',
                                            ref: 'rowHeight',
                                            text: this.$t('row_height'),
                                            width: '12em',
                                            showValue: true,
                                            min: 30,
                                            max: 70,
                                            onInput: this.onSettingsRowHeightChange
                                        },
                                        {
                                            type: 'slider',
                                            ref: 'barMargin',
                                            text: this.$t('bar_margin'),
                                            width: '12em',
                                            showValue: true,
                                            min: 0,
                                            max: 10,
                                            onInput: this.onSettingsMarginChange
                                        }
                                    ]
                                },
                                {
                                    type: 'button',
                                    cls: 'button button-primary',
                                    icon: 'b-fa b-fa-bars-staggered',
                                    text: this.$t('baseline'),
                                    onToggle: this.onBaselineClick,
                                    menu: Object.values(this.baselinesConfig)
                                },
                                {
                                    type: 'button',
                                    cls: 'button button-success',
                                    pressedCls: 'button-success-pressed',
                                    icon: 'b-fa b-fa-project-diagram',
                                    text: this.$t('dependencies'),
                                    toggleable: true,
                                    pressed: this.enableDependencies,
                                    onAction: ({ source }) => {
                                        this.ganttInstance.features.dependencies.disabled = !source.pressed;
                                        this.enableDependencies = source.pressed
                                    }
                                },
                                {
                                    type: 'button',
                                    cls: 'button button-danger',
                                    pressedCls: 'button-danger-pressed',
                                    icon: 'b-fa b-fa-fire',
                                    text: this.$t('critical_path_gantt'),
                                    toggleable: true,
                                    pressed: this.enableCriticalPaths,
                                    onAction: this.onCriticalPathsClick
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-search-plus',
                                    cls: 'button button-primary',
                                    onAction: () => this.ganttInstance.zoomIn()
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-search-minus',
                                    cls: 'button button-primary',
                                    onAction: () => this.ganttInstance.zoomOut()
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-compress-arrows-alt',
                                    cls: 'button button-primary',
                                    tooltip: this.$t('fit_content'),
                                    onAction: () => this.ganttInstance.zoomToFit()
                                },
                                {
                                    type: 'button',
                                    icon: 'b-fa b-fa-expand-arrows-alt',
                                    cls: 'button button-primary',
                                    pressedCls: 'button-primary-pressed',
                                    tooltip: this.$t('fullscreen'),
                                    toggleable: true,
                                    onAction: ({ source }) => {
                                        if (source.pressed) {
                                            this.ganttInstance.requestFullscreen();
                                        } else {
                                            this.ganttInstance.exitFullscreen();
                                        }
                                    }
                                }
                            ]
                        },
                    ]
                }),
                rowHeight: this.rowHeight,
                barMargin: this.barMargin,
                onExpandNode: this.handleExpandNode, // evento para cargar los hijos de un nodo
            });
            this.isLoading = false;

            this.ganttInstance.project.on('load', () => {
                // una vez cargado el gantt, ajustamos el zoom
                this.ganttInstance.zoomToFit();
                this.handleGrabb()
            });
        },

        onSettingsShow({ source }) {
            source.menu.widgetMap.rowHeight.value = this.ganttInstance.rowHeight;
            source.menu.widgetMap.barMargin.value = this.ganttInstance.barMargin;
        },

        onSettingsRowHeightChange({ source }) {
            this.ganttInstance.rowHeight = source.value;
            this.rowHeight = source.value;
        },

        onSettingsMarginChange({ source }) {
            this.ganttInstance.barMargin = source.value;
            this.barMargin = source.value;
        },

        onCriticalPathsClick({ source }) {
            for (let i = 0; this.ganttInstance.project.taskStore.registeredRecords.length > i; i++) {
                let task = this.ganttInstance.project.taskStore.registeredRecords[i];
                if ((task.critical || task.calculatedCritical) && task.type != 'assignment') {
                    task.calculatedCritical = true
                    task.eventColor = source.pressed ?
                        this.criticalColor :
                            this.isWelink ?
                                colors.defaults.scheduled.normal :
                                colors.activity[task.status].normal
                }
            }

            this.ganttInstance.features.criticalPaths.disabled = !source.pressed;
            this.enableCriticalPaths = source.pressed;
        },

        onBaselineClick({ source, checked }) {
            if (source.isMenuItem) {
                // revisamos si hay alguna linea base activa
                this.baselinesConfig[source.ref].checked = checked;
                if (Object.values(this.baselinesConfig).some(baseline => baseline.checked)) {
                    this.enableBaselines = true;
                    this.ganttInstance.features.baselines.disabled = false;
                } else {
                    this.enableBaselines = false;
                    this.ganttInstance.features.baselines.disabled = true;
                }

                const baselineElements = document.querySelectorAll(`.${source.ref}`);
                baselineElements.forEach(element => {
                    element.style.display = checked ? 'block' : 'none';
                });
            }
        },

        getBaselinesConfig() {
             if (this.isWelink) {
                this.baselinesConfig = {
                    'baseline-1' : {
                        ref: 'baseline-1',
                        checked: false,
                        text: this.$t('scheduled')
                    },
                    'baseline-2' : {
                        ref: 'baseline-2',
                        checked: false,
                        text: this.$t('inspected')
                    }
                }
             } else {
                this.baselinesConfig = {
                    'baseline-1' : {
                        ref: 'baseline-1',
                        checked: false,
                        text: this.$t('scheduled')
                    }
                }
             }
        },

        handleExpandNode({ record }) {
            if (record.children !== true || this.isLoadingChildren[record.id]) return;
            this.isLoadingChildren[record.id] = true;
            let children = this.mapChildren(record);
            let node = this.ganttInstance.project.taskStore.getById(record.id)
            node.activities = []
            node.groups = []
            node.assignmentsData = []
            node.replaceChildren(children);
            this.isLoadingChildren[record.id] = false;
        },

        // other methods

        async getData() {
            this.isLoading = true;
            const { data } = await axios.get(`/api/v2/project/${this.project.id}/gantt`);
            if (data && data.data && data.data.length) {
                this.data = this.mapData(data.data, this.isGroup(data.data[0]));
                this.getDependencies()
            }
        },

        mapData(data, isGroups = false, parent = null) {
            let res = []
            for (let item of data) {
                let startDate = item.current_start_date
                let endDate = item.current_end_date
                let clean = {
                    id: isGroups ? 'G'+item.id: item.id,
                    name: item.name,
                    code: item.code || item.planning_code,
                    status: item.status || null,
                    critical_path: item.critical_path || null,
                    parentId: item.parent_id || item.group_id || null,
                    percentDone: parseFloat(item.progress).toFixed(2),
                    startDate,
                    endDate,
                    type: isGroups ? 'group' : 'activity',
                    baselines: [],
                    user: item.user_nodes_by_managers?.[0]?.user.basic || null,
                    expanded: false,
                    draggable: false,
                    manuallyScheduled: true,
                    // almacenamos los diferentes tipos de hijos que puede tener un nodo para luego mapear cada que se despliegue
                    groups: item.children_groups,
                    activities: isGroups ? item.activities : item.children_activities,
                    assignmentsData: item.assignments,
                    // este campo puede ser una lista con los hijos o un boolean para indicarle al gantt que puede tener hijos
                    children: !!item.children_groups?.length || !!item.activities?.length || !!item.children_activities?.length || !!item.assignments?.length,
                    gantt_real_start_date: item.gantt_real_start_date,
                    gantt_real_end_date: item.gantt_real_end_date,
                    gantt_expected_end_date: item.gantt_expected_end_date,
                    calculatedCritical: parent?.calculatedCritical || false
                }

                if (isGroups) {
                    clean.cls = 'is-group'
                    clean.eventColor = colors.defaults.primary.normal
                }

                // if (!isGroups) {
                    /*
                    !welink:
                        - progress -> progreso
                        - la barra principal es el progreso y toma el color de el estado
                        - 1era linea base es el programado y toma el color azul

                    welink:
                        - progress_actual -> progreso
                        - progress -> inspeccionado
                        - la barra principal es el programado y toma el color azul (se calcula el progreso por la cantidad de dias que lleva desde inicio a fin)
                        - 1era linea base es el actual y toma el color verde
                        - 2da linea base es la inspeccionada y toma el color rojo
                    */
                    if (this.isWelink) {
                        clean.percentDone = parseFloat(item.progress_actual).toFixed(2);
                        clean.eventColor = clean.calculatedCritical && this.enableCriticalPaths ? this.criticalColor : colors.defaults.scheduled.normal;

                        // linea base
                        clean.baselines.push(
                            {
                                name: this.$t('scheduled'),
                                startDate: item.scheduled_start_date,
                                endDate: item.scheduled_end_date,
                                style: `background: ${colors.defaults.real.normal}; display: ${this.enableBaselines && this.baselinesConfig['baseline-1'].checked ? 'block' : 'none'}`,
                                progress: this.calculateProgress(item.scheduled_start_date, item.scheduled_end_date),
                                cls: 'baseline-1'
                            },
                            {
                                name: this.$t('inspected'),
                                startDate,
                                endDate,
                                style: `background: ${colors.defaults.inspected.normal}; display: ${this.enableBaselines && this.baselinesConfig['baseline-2'].checked ? 'block' : 'none'}`,
                                progress: parseFloat(item.progress).toFixed(2),
                                cls: 'baseline-2'
                            }
                        )
                    } else {
                        if (!isGroups) {
                            clean.eventColor = clean.calculatedCritical && this.enableCriticalPaths ? this.criticalColor : colors.activity[item.status].normal;
                        }

                        // linea base
                        clean.baselines.push({
                            name: this.$t('scheduled'),
                            startDate: item.scheduled_start_date,
                            endDate: item.scheduled_end_date,
                            style: `background: ${colors.defaults.scheduled.normal}; display: ${this.enableBaselines && this.baselinesConfig['baseline-1'].checked ? 'block':'none'}`,
                            cls: 'baseline-1'
                        })
                }
                if (clean.type == 'activity') {
                        clean = this.mapDateDiff(clean, parent)
                        clean.children = this.mapChildren(clean)
                        clean.activities = []
                        clean.assignmentsData = []
                        if (clean.children.length) {
                            let { minStartDate, maxEndDate } = clean.children.reduce(
                                (acc, child) => ({
                                    minStartDate: child.startDate < acc.minStartDate ? child.startDate : acc.minStartDate,
                                    maxEndDate: child.endDate > acc.maxEndDate ? child.endDate : acc.maxEndDate,
                                }),
                                { minStartDate: clean.startDate, maxEndDate: clean.endDate }
                            );

                            if (minStartDate < clean.startDate) clean.startDate = minStartDate;
                            if (maxEndDate > clean.endDate) clean.endDate = maxEndDate;
                        }
                    }
                // }

                res.push(clean)
            }
            return res
        },

        isGroup(item) {
            return item.code !== undefined;
        },

        mapDateDiff(item, parent = null) {
            if (item.type != 'activity') return item;

            if (item.gantt_real_start_date) {
                let diff = moment(item.gantt_real_start_date).diff(moment(item.startDate), 'days');
                item.diff = diff;
                item.startDate = item.gantt_real_start_date;
                if (item.gantt_real_end_date) {
                    item.endDate = item.gantt_real_end_date
                } else if (item.gantt_expected_end_date) {
                    item.endDate = item.gantt_expected_end_date
                } else {
                    item.endDate = moment(item.endDate).add(diff, 'days').format('YYYY-MM-DD')
                }
            } else if (parent && parent.diff) {
                item.startDate = moment(item.startDate).add(parent.diff, 'days').format('YYYY-MM-DD')
                item.endDate = moment(item.endDate).add(parent.diff, 'days').format('YYYY-MM-DD')
            }

            if (item.status != 4 && !item.gantt_real_end_date && moment(item.endDate).isBefore(moment())) {
                item.endDate = moment().format('YYYY-MM-DD');
            }

            return item
        },

        mapChildren(item) {
            if (item.groups?.length) {
                return this.mapData(item.groups, true, item)
            }

            if (item.activities?.length) {
                return this.mapData(item.activities, false, item)
            }

            if (item.assignmentsData?.length) {
                return this.mapAssignments(item)
            }

            return []
        },

        mapAssignments(item) {
            let res = []
            for (let assignment of item.assignmentsData) {
                let clean = {
                    id: 'T'+assignment.id,
                    name: assignment.name,
                    code: assignment.code,
                    status: assignment.status,
                    percentDone: this.calculatePercentage(assignment).toFixed(2),
                    startDate: item.startDate,
                    endDate: item.endDate,
                    type: 'assignment',
                    parentId: item.id,
                    draggable: false,
                    manuallyScheduled: true,
                    eventColor: colors.assignment[assignment.status].normal,
                    cls: 'is-assignment'
                }
                res.push(clean)
            }
            return res
        },

        async getDependencies() {
            const { data } = await axios.get(`/api/v2/project/${this.project.id}/get-prelations`);

            if (data && data.data) {
                this.dependencies = data.data;
            }
            this.initGantt();
        },

        // progreso de mediciones
        calculatePercentage(assignment) {
            if (assignment.total_quantity != null) {
                let percentage = (100 / assignment.total_quantity) * assignment.total_executed_quantity;
                if (percentage > 100) percentage = 100;
                return percentage || 0;
            } else
                return (assignment.completed) ? 100 : 0;
        },

        // calcula el porcentaje que ha pasado con respecto a la fecha de inicio y fin (programado)
        calculateProgress(startDate, endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const now = moment();

            if (now.isBefore(start)) {
                return 0;
            }

            if (now.isSameOrAfter(end)) {
                return 100;
            }

            const totalDuration = end.diff(start, 'milliseconds');
            const elapsedDuration = now.diff(start, 'milliseconds');
            const progress = (elapsedDuration / totalDuration) * 100;

            return parseFloat(progress.toFixed(2));
        },

        handleGrabb() {
            let containerIds = ['.b-grid-subgrid-locked', '.b-grid-subgrid-normal'];
            for (let contId of containerIds) {
                const container = document.querySelector(contId);
                if (!container) continue;
                let isDown = false;
                let startX, startY, scrollLeft, scrollTop;
    
                container.addEventListener('pointerdown', (e) => {
                    isDown = true;
                    startX = e.pageX - container.offsetLeft;
                    startY = e.pageY - container.offsetTop;
                    scrollLeft = container.scrollLeft;
                    scrollTop = container.scrollTop;
                    container.style.cursor = "grabbing"; // Cambia el cursor
                });
    
                container.addEventListener('pointerleave', () => {
                    isDown = false;
                    container.style.cursor = "auto";
                });
    
                container.addEventListener('pointerup', () => {
                    isDown = false;
                    container.style.cursor = "auto";
                });
    
                container.addEventListener('pointermove', (e) => {
                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - container.offsetLeft;
                    const y = e.pageY - container.offsetTop;
                    const walkX = (x - startX) * 2; // Ajusta la velocidad horizontal
                    const walkY = (y - startY) * 2; // Ajusta la velocidad vertical
                    container.scrollLeft = scrollLeft - walkX;
                    container.scrollTop = scrollTop - walkY;
                });
            }
        }
    }
};
</script>

<style>
    :root {
        --gantt-primary: #57b;
        --gantt-primary-hover: #68c;
        --gantt-primary-light: #e4ebf8;
        --gantt-primary-light-hover: #dce5f8;
        --gantt-primary-light-active: #c8d4f0;

        --gantt-success: #2a9d8f;
        --gantt-success-hover: #38b2a3;
        --gantt-success-light: #e3f6f5;
        --gantt-success-light-hover: #d4f1ef;
        --gantt-success-light-active: #c1e8e0;

        --gantt-danger: #d62828;
        --gantt-danger-hover: #e63946;
        --gantt-danger-light: #f8e4e4;
        --gantt-danger-light-hover: #f8dcdc;
    }

    .b-grid-subgrid {
        background-image: none !important;
    }

    .b-gantt-task-parent .b-sch-label-top {
        position: relative;
        top: -0.25em;
    }

    .b-gantt-task-parent .b-sch-label-bottom {
        margin-top: -0.75em;
    }

    .b-sch-color-none.b-gantt-task-wrap.b-gantt-task-parent:not(.b-milestone-wrap) .b-gantt-task {
        min-height: 1.8em;
    }

    .b-gantt-task-content {
        display: flex;
    }

    .b-gantt-task-parent .b-gantt-task-content {
        display: flex;
        height: 1.1em;
        align-items: center;
        align-self: flex-start;
    }

    .b-sch-event-wrap .b-sch-label-bottom,
    .b-gantt-task-wrap .b-sch-label-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        margin-top: 0;
    }

    .b-sch-event-wrap .b-sch-label-bottom .b-day-hours,
    .b-gantt-task-wrap .b-sch-label-bottom .b-day-hours {
        height: 100%;
        padding: 0.25em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        text-align: center;
        font-weight: 400;
        font-size: 0.75rem;
    }

    .b-gantt-task-wrap .b-sch-label-top {
        overflow: hidden;
        font-weight: 400;
        font-size: 0.75rem;
        padding: 0.25em;
    }

    .b-gantt-task-wrap .b-sch-label-top i {
        margin-inline: 1em 0.5em;
    }

    .b-gantt-name {
        font-size: 0.75em;
        margin: auto;
    }

    .b-grid-header-container,
    .b-grid-headers .b-last-leaf {
        /* background: var(--gantt-primary-light);
        color: var(--gantt-primary); */
        font-size: 14px;
    }

    /* .b-grid-header-text-content {
        color: var(--gantt-primary);
    }

    #gantt-container .b-resizing,
    #gantt-container .b-drop-placeholder {
        background-color: var(--gantt-primary-light-hover) !important;
    }

    .b-grid-header.b-drag-proxy {
        background-color: var(--gantt-primary-light-hover) !important;
    }

    .b-gridbase.b-sort .b-grid-header.b-sort .b-grid-header-text .b-sort-icon:before {
        color: var(--gantt-primary) !important;
    }

    .b-horizontaltimeaxis .b-sch-header-timeaxis-cell {
        color: var(--gantt-primary);
    }

    .b-horizontaltimeaxis .b-sch-header-timeaxis-cell:hover {
        background: var(--gantt-primary-light-hover) !important;
    } */

    .b-grid-splitter-inner {
        background: #57b3 !important;
    }

    .b-tree-cell-value,
    .b-grid-cell {
        font-size: 12px;
    }

    .b-panel.b-html .b-panel-content {
        background: var(--gantt-primary);
        padding: 12px;
        margin: 0;
        font-size: 14px;
    }

    .tooltip-container * {
        line-height: 1.5em;
    }

    .b-baseline-wrap {
        min-height: 14px;
    }

    .b-task-percent-bar {
        background: #ffffff54;
    }

    #gantt-container .button {
        font-size: 12px;
        height: 10px;
    }

    #gantt-container .button-primary {
        color: var(--gantt-primary);
        background-color: var(--gantt-primary-light);
    }

    #gantt-container .button-primary:hover,
    #gantt-container .button-primary:focus {
        color: var(--gantt-primary);
        background-color: var(--gantt-primary-light-hover);
    }

    #gantt-container .button-primary-pressed {
        background-color: var(--gantt-primary);
        color: #fff;
    }

    #gantt-container .button-primary-pressed:hover,
    #gantt-container .button-primary-pressed:focus {
        background-color: var(--gantt-primary-hover);
        color: #fff;
    }


    #gantt-container .button-success {
        color: var(--gantt-success);
        background-color: var(--gantt-success-light);
    }

    #gantt-container .button-success:hover,
    #gantt-container .button-success:focus {
        color: var(--gantt-success);
        background-color: var(--gantt-success-light-hover);
    }

    #gantt-container .button-success-pressed {
        background-color: var(--gantt-success);
        color: #fff;
    }

    #gantt-container .button-success-pressed:hover,
    #gantt-container .button-success-pressed:focus {
        background-color: var(--gantt-success-hover);
        color: #fff;
    }

    #gantt-container .button-danger {
        color: var(--gantt-danger);
        background-color: var(--gantt-danger-light);
    }

    #gantt-container .button-danger:hover,
    #gantt-container .button-danger:focus {
        color: var(--gantt-danger);
        background-color: var(--gantt-danger-light-hover);
    }

    #gantt-container .button-danger-pressed {
        background-color: var(--gantt-danger);
        color: #fff;
    }

    #gantt-container .button-danger-pressed:hover,
    #gantt-container .button-danger-pressed:focus {
        background-color: var(--gantt-danger-hover);
        color: #fff;
    }

    .b-menu div {
        color: var(--gantt-primary) !important;
        background-color: #fff !important;
        font-size: 14px;
    }

    #gantt-container .b-icon-calendar {
        display: none;
    }

    .b-grid-subgrid-locked>.is-group {
        background: var(--gantt-primary-light) !important;
    }

    .b-grid-subgrid-locked>.is-group.b-selected {
        background: var(--gantt-primary-light-active) !important;
    }

    .b-grid-subgrid-locked>.is-assignment {
        background: var(--gantt-success-light) !important;
    }

    .b-grid-subgrid-locked>.is-assignment.b-selected {
        background: var(--gantt-success-light-active) !important;
    }
</style>
