<template>
  <b-modal :active.sync="isModalActive" :width="320" :can-cancel="canCancel" @close="() => { hide() }">
    <div v-if="isLoading">
      <scale-loader></scale-loader>
    </div>

    <div class="border border-blue-light rounded shadow bg-white" v-if="!isLoading">
      <header class="w-full p-4 text-xl font-thin uppercase text-blue-light">
        {{ $t( form.id != null  ? 'edit_status' : 'add_status') }}
      </header>
      <div class="w-full px-4 pb-0 pt-0">
        <form @submit.prevent="updateStatus" class="w-full">
          <div class="mb-1">

            <!-- <div class="w-full pb-3">
              <div class="w-full">
                <label form="code" class="">{{ $t('code')}}</label>
                <v-input
                  v-model="form.code"
                  title="code"
                  type="text"
                  name="code"
                  placeholder="code"
                />
              </div>
            </div> -->

            <div class="w-full pb-3">
              <div class="w-full">
                <label form="name" class="">{{ $t('name')}}</label>
                <v-input
                  v-model="form.name"
                  title="name"
                  type="text"
                  required="true"
                  name="name"
                  placeholder="name"
                />
              </div>
            </div>

            <div class="w-full pb-3">
              <div class="w-full">
                <b-field>
                  <b-switch v-model="form.is_completion" :value="form.is_completion" type="is-success">Está completado</b-switch>
                </b-field>
              </div>
            </div>

            <div class="w-full pb-3">
              <label form="color">{{ $t('select_a_color') }}</label>
              <b-colorpicker v-model="form.color"
                :alpha="false"
                inline
                size="is-medium"
                :color-formatter="formatter" />
            </div>

            <div class="w-full inline-flex justify-end pb-3">
              <button
                class="btn btn-blue w-full"
                nativeType="submit"
              >
                {{ $t( form.id != null ? 'edit' : 'add' ) }}
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import Form from 'vform'
  import {mapGetters} from "vuex";
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  // import Color from 'buefy/src/utils/color'

  export default {
    name: "pictogram-status-modal",

    components: {
      ScaleLoader,
    },

    props: {
    },

    data: () => ({
      isModalActive: false,
      isOpen: false,
      isLoading: true,
      assignmentId: 0,

      processingData: false,

      canCancel: ['escape', 'x', 'outside'],

      registerOverDrawingMode: false, // indica si la medición se realizará sobre un plano

      type: null,
      form: new Form({
        id: null,
        code: null,
        name: null,
        is_completion: false,
        color: null,
      }),

      formatColor: 'hex',
    }),

    mounted() {

    },

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    watch: {
      registerOverDrawingMode(newValue, oldValue) {
        this.$nextTick(() => {
          this.modalWidth = (newValue ? '90%' : '30%')
        });
      },
    },

    methods: {
      formatter (color) {
        return color.toString(this.formatColor)
      },

      /**
       * Actualiza el estado llamando a la API
       * NOTA:No implementado
       */
      async updateStatusRemote() {

        this.form.entity_type = this.entityType
        const {data} = await this.form.post('/api/v2/pictogram/status/' + this.status.id);

        if (data.success) {

          this.$toast.open({
            message: this.$t('status_change_success'),
            type: 'is-success',
            position: 'is-top-right'
          });

          this.hide();
          this.$emit("reload");

        } else {

          this.isLoading = false;
          let msg = data.error_msg ? data.error_msg : this.$t('status_change_fail');

          // Error
          this.$toast.open({
            message: msg,
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },

      /**
       * Actualiza el estado localmente
       */
       async updateStatus() {
        // this.$toast.open({
        //   message: msg,
        //   type: 'is-danger',
        //   position: 'is-top-right'
        // })
        this.$emit('epc-add-element', this.type, {
          id: this.form.id,
          code: this.form.code,
          name: this.form.name,
          color:  this.form.color == null || this.formatter(this.form.color) === '#000000' ? null : this.formatter(this.form.color),
          is_completion: this.form.is_completion,
        })

        this.hide()
      },

      handleProcessingData(event) {
        this.processingData = event
        if( ! event ) {
          this.canCancel = ['escape', 'x', 'outside']
          this.hide()
        }
        else
          this.canCancel = !event
      },

      handleProcessingDataFinished(event) {
        this.processingData = false
        this.canCancel = ['escape', 'x', 'outside']

        this.hide()
      },

      show(type, status=null) {
        this.isLoading = true
        this.isModalActive = true;
        this.canCancel = ['escape', 'x', 'outside']

        if (status != null) {
          this.form.id = status.id && status.id != null ? status.id : null
          this.form.code = status.code
          this.form.name = status.name
          this.form.color = status.color
          this.form.is_completion = status.is_completion
        }

        this.type = type
        this.processingData = false

        this.$nextTick(() => {
          setTimeout(() => {
            // Simulate asynchronous task completion
            this.isLoading = false
          }, 1000);
        });
      },

      hide() {
        this.assignmentId = 0;

        this.form.id = null
        this.form.code = null
        this.form.name = null
        this.form.color = null
        this.form.is_completion = false

        this.processingData = false
        this.isModalActive = false;
        this.registerOverDrawingMode = false
      },
    }
  };
</script>

<style scoped>
::v-deep.max-width-custom .modal-content {
  max-width: 90% !important;
}
</style>
