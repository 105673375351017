<template>
  <div>
    <div class="w-full flex mb-2">
      <div class="w-1/2 pr-2">
        <treeselect v-model="selectedItem" :close-on-select="true" :required="false" :options="data" />
      </div>
      <div class="w-1/2 pr-2">
        <v-button native-type="button" class="w-full"
          style="padding-top: 0.5rem !important;padding-bottom: 0.5rem !important;" @click="save()"
          :disabled="!selectedItem">Agregar
        </v-button>
      </div>
    </div>

    <div style="min-height: 40px; background: #fff;" class="p-1 rounded">
      <div v-for="(value, i) in selected" :key="value.id">
        <div class="w-full border border-blue-lighter rounded flex p-1 bg-blue-lightest mb-1">
          <div @click="remove(i)" class="cursor-pointer text-red">
            <b-icon pack="fas" icon="trash-alt" />
          </div>
          <span class="ml-2">{{ getLabel(value) }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'

export default {

  name: "multiselect",

  components: {
    Treeselect
  },

  props: {
    data: { type: Array, required: true },
    selected: { type: Array, default: () => [] },
  },

  data: () => ({
    selectedItem: null,
  }),

  methods: {
    save() {
      if (!this.selected.includes(this.selectedItem)) {
        this.selected.push(this.selectedItem);
        this.$emit('updated', this.selected);
      }
      this.selectedItem = null;
    },

    remove(index) {
      this.selected.splice(index, 1);
      this.$emit('updated', this.selected);
    },

    getLabel(id) {
      for (let item of this.data) {
        if (item.id == id) {
          return item.label
        }
        for (let child of item.children) {
          if (child.id == id) {
            return child.label
          }
        }
      }
      return ''
    },
  }
}
</script>

<style>

</style>
