<template>
  <!-- p-4 bg-white border rounded shadow-lg border-blue-light -->
  <div class="relative" :class="{ 'h-20' : isLoading }">
    <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
    <div class="w-full" v-if="!isLoading">
      <form @submit.prevent="submitForm" class="w-full">
        <div>
          <div class="flex flex-row items-center">
            <div class="w-3 h-3 mr-1 rounded-full bg-green"></div>
            <div class="font-semibold text-black">{{ data.name }}</div>
          </div>

          <div v-if="data.unit">
            <div v-if="isActualProgress" class="flex flex-row items-center justify-start mt-1">
              <div class="px-2 text-xs font-semibold text-white rounded-full bg-blue lowercase">{{ data.executed_quantity_actual ? data.executed_quantity_actual.toFixed(2) : '0' }} {{ data.unit.name }} {{ $t('executed') }} ({{ $t("actual") }})</div>
              <div class="ml-1 text-xs font-medium text-grey-dark">{{ (form.total - (data.executed_quantity_actual || 0) == 0) ? '0' : (form.total - (data.executed_quantity_actual || 0)).toFixed(2) }} {{ data.unit.name }} {{ $t('pendings') }}</div>
            </div>
            <div class="flex flex-row items-center justify-start mt-1">
              <div class="px-2 text-xs font-semibold text-white rounded-full bg-blue ">{{ (data.executed_quantity == 0) ? '0' : data.executed_quantity.toFixed(2) }} {{ data.unit.name }} {{ executedLabel }}</div>
              <div class="ml-1 text-xs font-medium text-grey-dark">{{ ((form.total - data.executed_quantity) == 0) ? '0' : (form.total - data.executed_quantity).toFixed(2) }} {{ data.unit.name }} {{ $t('pendings') }}</div>
            </div>
          </div>

          <!-- fila datos ultima actualizacion -->
          <div class="flex flex-row justify-between w-full mt-4" v-if="existsLastLine">
            <div class="flex flex-col w-full text-xs font-medium text-gray">{{ $t('last_update') }}</div>
          </div>

          <div class="flex flex-row w-full p-1 border-2 border-orange-lighter bg-orange-lightest" style="border-radius: 10px;"
            v-if="existsLastLine">
              <div class="flex flex-col w-1/3 text-xs">
                <div class="font-medium capitalize text-gray">{{ $t("units") }}</div>
                {{ lastLine.units || '-' }}
              </div>
              <div class="flex flex-col w-1/3 text-xs">
                <div class="font-medium capitalize text-gray">{{ $t("length") }}</div>
                {{ lastLine.length || '-' }}
              </div>
              <div class="flex flex-col w-1/3 text-xs">
                <div class="font-medium capitalize text-gray">{{ $t("width") }}</div>
                {{ lastLine.width || '-' }}
              </div>
              <div class="flex flex-col w-1/3 text-xs">
                <div class="font-medium capitalize text-gray">{{ $t("height") }}</div>
                {{ lastLine.height || '-' }}
              </div>
          </div>

          <div class="mt-2">
            <b-field class="text-xs">
              <b-switch
                  v-model="registerMode"
                  passive-type='is-dark'
                  type='is-success'
                  @input="handleInputSwitch">
                  {{ $t('register_progress_on_drawing') }}
              </b-switch>
            </b-field>
          </div>

          <div class="flex flex-row justify-between w-full mt-4">

            <!-- campo unidades -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.total_units || (existsLastLine && !lastLine.units)" -->
            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("units") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="calculation.units"
                  title="units"
                  :disabled="data.completed || (existsLastLine && !lastLine.units) || registerMode"
                  :required="existsLastLine && lastLine.units"
                  name="units"
                  step=".01"
                  type="number"
                  class="w-16 h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                  @focus="$event.target.select()"
                  @keyup="calculateExecutedQuantity" />
                <span class="ml-1 text-xs text-black">({{ data.total_units || 'N/A'  }})</span>
              </div>
            </div>

            <!-- campo largo -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.length  || (existsLastLine && !lastLine.length)" -->
            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("length") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="calculation.length"
                  title="length"
                  :disabled="data.completed || (existsLastLine && !lastLine.length)"
                  :required="existsLastLine && lastLine.length"
                  name="length"
                  step=".01"
                  type="number"
                  class="w-16 h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                  @focus="$event.target.select()"
                  @keyup="calculateExecutedQuantity" />
                <span class="ml-1 text-xs text-black">({{ data.length || 'N/A'  }})</span>
              </div>
            </div>

            <!-- campo ancho -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.width  || (existsLastLine && !lastLine.width)" -->
            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("width") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="calculation.width"
                  title="width"
                  :disabled="data.completed || (existsLastLine && !lastLine.width)"
                  :required="existsLastLine && lastLine.width"
                  name="width"
                  step=".01"
                  type="number"
                  class="w-16 h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                  @keyup="calculateExecutedQuantity" />
                <span class="ml-1 text-xs text-black">({{ data.width || 'N/A'  }})</span>
              </div>
            </div>

            <!-- campo alto -->
            <!-- esta linea la editamos de momento para que permita ingresar progreso -->
            <!-- :disabled="data.completed || !data.height || (existsLastLine && !lastLine.height)" -->
            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium capitalize text-blue">{{ $t("height") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="calculation.height"
                  title="height"
                  :disabled="data.completed || (existsLastLine && !lastLine.height)"
                  :required="existsLastLine && lastLine.height"
                  name="height"
                  step=".01"
                  type="number"
                  class="w-16 h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light"
                  @keyup="calculateExecutedQuantity" />
                <span class="ml-1 text-xs text-black">({{ data.height || 'N/A' }})</span>
              </div>
            </div>

          </div>

          <div class="flex flex-row justify-between w-full mt-4">

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium text-blue">{{ $t("executed_quantity") }}</div>
              <div class="flex flex-row items-center">
                <input
                  v-model="form.executed_quantity"
                  title="executed_quantity"
                  :disabled="true"
                  :required="this.isResource()"
                  name="executed_quantity"
                  step=".01"
                  type="number"
                  class="w-16 h-8 p-1 text-xs text-right border rounded bg-blue-lighter border-blue-light" />
                <span v-if="data.unit" class="ml-1 text-xs text-black">{{ data.unit.code}}</span>
              </div>
            </div>

            <div class="flex flex-col w-1/3 mx-4 ">
              <div class="text-xs font-medium text-blue">{{ $t("start_date") }}</div>
              <div class="flex flex-row">

                <input
                  v-model="form.start_date"
                  title="start_date"
                  :disabled="data.completed"
                  :required="this.isResource()"
                  name="start_date"
                  type="date"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light" />
              </div>

            </div>

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium text-blue">{{ $t("end_date") }}</div>
              <div class="flex flex-row">
                <input
                  v-model="form.end_date"
                  title="end_date"
                  :disabled="data.completed"
                  :required="this.isResource()"
                  name="end_date"
                  type="date"
                  class="w-full h-8 p-1 text-xs border rounded bg-grey-lighter border-grey-light" />
              </div>
            </div>

          </div>

          <div class="flex flex-row w-full mt-4">

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium text-blue">{{ $t('progress_label') }}: </div>
              <b-radio v-model="form.progress_type" native-value="origin" :disabled="registerMode">
                <div class="text-xs font-medium text-blue">{{ $t('progress_origin') }}</div>
              </b-radio> <br/>
              <b-radio v-model="form.progress_type" native-value="partial">
                <div class="text-xs font-medium text-blue">{{ $t('progress_partial') }}</div>
              </b-radio>
            </div>

            <div class="flex flex-col w-1/3 mx-4">
              <div class="text-xs font-medium">{{ $t("start_date") }} {{ $t('activity') }}</div>
              <div class="text-xs">{{ currentStartDate }}</div>
            </div>

            <div class="flex flex-col w-1/3">
              <div class="text-xs font-medium">{{ $t("end_date") }} {{ $t('activity') }}</div>
              <div class="text-xs">{{ currentEndDate }}</div>
            </div>

          </div>


          <!-- Observación del cambio de recurso-->
          <div class="w-full pb-3 mt-4" v-if="isResource()" >
              <div class="text-xs font-medium text-blue">{{ $t("observations") }}</div>
              <b-input
                v-model="form.observation"
                name="observation"
                :placeholder="$t('observations')"
                :disabled="!hasExecutedQuantityChanges()"
                class="w-full"
                maxlength="2000"
                type="textarea"
              ></b-input>

              <div class="flex flex-row justify-between w-full mt-4">
                <div class="flex flex-col">
                  <b-field class="file is-success">
                    <b-upload
                      v-model="files"
                      class="file-label"
                      :multiple="true"
                      :accept="accept"
                    >
                      <span class="file-cta">
                        <span class="mr-2 file-label">{{
                          $t("upload_documents")
                        }}</span>
                        <b-icon class="file-icon" icon="upload" pack="fas"></b-icon>
                      </span>
                    </b-upload>
                  </b-field>
                </div>
                <div class="flex flex-col">
                  <b-switch
                    v-model="copyDocument"
                    type='is-success'>
                   {{ $t('copy_documents') }}
                  </b-switch>
                </div>
              </div>

            <div class="tags" v-if="filesSelected">
              <span v-for="(file, index) in files"
                :key="index"
                class="tag is-primary"
              >
                {{ file.name }}
                <button
                  class="delete is-small"
                  type="button"
                  @click="removeFile(index)"
                ></button>
              </span>
            </div>
            <!--button Copiar documento clich: showModalSelectFolder-->
            <div v-if="copyDocument" class="flex flex-row justify-between w-full mt-4">
              <div class="flex flex-col">
                <b-button
                  @click="showModalSelectFolder = true"
                  type="is-success"
                  :disabled="!filesSelected"
                  icon-pack="fas"
                  icon-left="copy"
                  >
                  {{ $t('select_folder') }}
                </b-button>
              </div>
            </div>
            <!-- MOSTRAR DATOS SI EXISTE DATAFOLDERCOPY FOLDERNAME DIFERENTE DE NULL -->
          <div>
            <div v-if="copyDocument">
              <div class="flex flex-row justify-between w-full mt-4">
                <div class="flex flex-col">
                  <div class="text-xs font-medium text-blue m-2">{{ $t('activity_name') }}</div>
                  <div class="text-xs m-2">{{ dataFolderToCopy.activityName }}</div>
                </div>
                <div class="flex flex-col">
                  <div class="text-xs font-medium text-blue m-2">
                    {{ $t('destination_folder') }}
                </div> 
                  <div class="text-xs m-2">{{ dataFolderToCopy.folderName }}</div>
                </div>
                
              </div>
            </div>
          </div>
            <!-- <div v-if="isLoading" class="my-4 text-center">
              <scale-loader />
            </div> -->
          </div>
        </div>

        <div class="inline-flex justify-end w-full pb-3">
          <!-- <button
            v-if="isAdmin()"
            @click.prevent="deleteAssignment"
            :disabled="isLoading"
            class="mr-1 btn btn-red"
            type="button"
          >
            {{ $t("delete") }}
          </button> -->
          <button class="btn btn-blue" type="submit" :disabled="isLoading">{{ $t("save") }}</button>
        </div>
      </form>

    </div>
    <b-modal v-model="showModalSelectFolder" size="lg" scroll="keep">
      <div class="flex flex-col border border-blue-light rounded shadow bg-white p-4">
        <select-folders
        :folderId="moveFolderId"
        :documentId="moveDocumentId"
        :parentId="selectedEntityId"
        :callbackFunction="copyToSelectedFolder"

        @close="openFolder(lastSelectedFolder)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { mapGetters } from "vuex";
import uploadDocument from "../upload-document";
import EventBus from "~/plugins/bus";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import selectFolders from "./select-folders";
import moment from 'moment';
import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import store from "~/store";

Vue.use(moment);
export default {
  name: "assignment-contribute-modal",

  components: {
    uploadDocument,
    ScaleLoader,
    selectFolders,
  },

  props: {
    entityId: { type: Number, required: true },
    entityType: { type: String, required: true },
    currentStartDate: { type: String, required: true },
    currentEndDate: { type: String, required: true },

    assignmentId: { type: Number, required: true },
    registerOverDrawingMode: { type: Boolean, default: false }, // indica si la medición se realizará sobre un plano
    isActualProgress: { type: Boolean, default: false }, // indica si se está registrando el avance real (true) o el normal (false)
  },

  data: function() {
    return {
      cancelRequest: null,
      uploadPercentage: 0,
      id: null,
      isLoading: false,
      data: {},
      unitsList: [],
      form: {},
      files: [],
      filesCopy : [],
      lastLine: {},
      calculation: {
        units: null,
        length: null,
        width: null,
        height: null,
      },
      accept: "", // on created()

      uppy: null, // instancia de la libreria para subir archivos
      keyFileUploadCurrent: null, // key del archivo que se esta subiendo actualmente
      registerMode: this.registerOverDrawingMode,

      showModalSelectFolder: false,
      selectedEntityId: null,
      moveDocumentId: null,
      moveFolderId: null,

      dataFolderToCopy:{
        folderId: null,
        activityId: null,
        folderName: null,
        activityName: null
      },
      copyDocument: false,

      ASSIGNMENTLINESTATUS_OK: 0,
      ASSIGNMENTLINESTATUS_ACTUAL_PROGRESS: 10,
    }
  },

  created() {

    this.accept = this.$acceptedExtensions // extensiones aceptadas

    this.uppy = new Uppy({
        debug: process.env.NODE_ENV === 'development', // activamos debug para development
        autoProceed: false,
        allowMultipleUploads: false,
      })
      .use(AwsS3Multipart, {
        limit: 3,
        companionUrl: '/api/v2/',
        companionHeaders: {
          'Authorization': "Bearer " + store.getters["auth/token"]
        }
      })
      // .on('upload-success', (file, response) => { this.onUploadSuccess(file, response) } )
      .on('upload-success', (file, response) => { // callback deveulto cuando el file ha sido subido correctamente
        console.log('%cEPC-TACKER: '+ '%c file ' + file.data.name + ' subido correctamente', 'background: #5577BB; color: #fff', 'color: #000')
      })
      .on('upload-progress', (file, progesss) => { // callback devuelto con el progreso de subido del archivo
        this.uploadPercentage = parseInt(
          Math.round((progesss.bytesUploaded * 100) / progesss.bytesTotal)
        );
      })
      .on('upload-error', (file, error, response) => { // callback devuelto si ha ocurrido algun error en la subida
        console.error('%cEPC-TACKER: '+ '%c a ocurrido un error al subir el archivo ' + file.data.name, 'background: #5577BB; color: #fff', 'color: #000', error)
      })
  },

  mounted() {
    this.id = this.assignmentId
    this.getProjectUnits();
    this.getAssignmentData();
    this.calculation = {
      units: null,
      length: null,
      width: null,
      height: null,
    }
  },

  computed: {

    ...mapGetters({
      project: "app/project",
      company: "app/company",
      user: "auth/user",
    }),

    filesSelected() {
      return this.files.length > 0 || this.files.size > 0;
    },

    existsLastLine() {
      // consideramos que existe linea si alguno de los nuevos campos no es null (u, l, w, h no existian en viejo formato)
      return this.lastLine.units || this.lastLine.length || this.lastLine.width || this.lastLine.height
    },

    executedLabel() {
      let label = this.$t('executed');
      if (this.company.alias == 'welink') {
        label = this.$t('inspected');
      }
      return label;
    },

  },

  methods: {
    /**
     * Envía el formulario para editar y/o añadir una linea
     */
    copyToSelectedFolder( folderId, ActivityId, folderName, activityName ) {
      this.dataFolderToCopy.folderId = folderId
      this.dataFolderToCopy.activityId = ActivityId
      this.dataFolderToCopy.folderName = folderName
      this.dataFolderToCopy.activityName = activityName

      this.showModalSelectFolder = false
      this.filesCopy = [ ...this.files ]
    },

    async submitForm() {
      this.$emit("processing-data", true)

      // Si registra progreso a origen. Calculamos la cantidad ejecutada previamente mirando un campo u otro
      let previouslyExecutedQuantity = this.isActualProgress
        ? this.data.executed_quantity_actual
        : this.data.executed_quantity

      let data = {
        executed_quantity: this.form.progress_type == 'origin'
          ? this.form.executed_quantity - previouslyExecutedQuantity
          : this.form.executed_quantity,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
        observation: this.hasExecutedQuantityChanges() ? this.form.observation : null,
        executed_units: this.calculation.units == '' ? null : this.calculation.units,
        executed_length: this.calculation.length == '' ? null : this.calculation.length,
        executed_width: this.calculation.width == '' ? null : this.calculation.width,
        executed_height: this.calculation.height == '' ? null : this.calculation.height,
        is_actual_progress: this.isActualProgress,
      };

      let url =
        "/api/v2/assignments/" +
        this.entityType +
        "/" +
        this.entityId +
        "/" +
        this.id;

      axios({
        method: "post",
        url: url,
        data: data,
      })
        .then((response) => {

          if (response.data && response.data.success) {

            this.$toast.open({
              message: response.data.error || this.$t("assignment_update_success"),
              type: response.data.error ? "is-danger" : "is-success",
              position: "is-top-right",
            });

            this.$emit("reload", { id: this.id, entityId: this.entityId }); //pide a su componente padre que recargue

            EventBus.$emit("refreshActivityDocumentsNotifications", true);

          } else {

              this.$toast.open({
                message: response.data.msg || this.$t("assignment_update_error"),
                type: "is-danger",
                position: "is-top-right"
              });
          }

          if( this.registerMode ) // solo si se realiza el registro desde plano mandamos evento para guardar el dato
            this.$emit("save-pdf", response.data.data)
          else
            this.$emit("processing-data", false)
        })
        .catch((error) => {
          //Se produjo un error
          // this.isLoading = false;
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("assignment_update_error"),
            type: "is-danger",
            position: "is-top-right",
          });
        });

      if (this.filesSelected) {
        await this.uploadFilesAll();
      }

      this.hide();
      this.$emit("reload");
    },


    // no esta siendo usada debido a algun bug que hacia que solo se suba el ultimo fichero seleccionado
    // usando uploadFilesAll en su lugar
    async uploadFiles() {
      if (this.files.length == 0) {
        return;
      }

      this.isLoading = true;
      let files = this.files.slice();
      for (var i = files.length - 1; i >= 0; i--) {
        if (files[i].size / 1024 / 1024 > 1024) {
          this.$notify.warning("document_exceeded_max_size", {
            name: files[i].name,
          });
        } else {
          let ok = await this.submitFile(files[i]);
          if (ok) {
            this.files.splice(i, 1);
            this.$notify.success("upload-document_success", {
              files: files[i].name,
            });
          } else {
            this.isLoading = false;
            // this.$notify.error("upload-document_error");
            return false;
          }
        }
      }

      // Todos los ficheros subidos
      if (this.files.length == 0) {
        this.isLoading = false;
        this.$notify.success("upload-document_success", {
          files: files.length,
        });
        this.$emit("success");
        return true;
      }
    },
    async uploadFilesAll() {
      if (this.files.length == 0) {
        return;
      } 
      this.isLoading = true;
      
      for (const file of this.files) {
        try {
          // TODO. Deberia comprobar filesize, al igual que en uploadFiles      
          const result = await this.submitFile(file); // Sube cada archivo secuencialmente
        } catch (error) {
          console.error(`Error en el archivo ${file.name}:`, error);
          this.$notify.error(`Error crítico al subir ${file.name}`);
        }
      }

      this.isLoading = false;
    },

    async submitFile(file) {

      this.uploadPercentage = 0;
      this.cancelRequest = axios.CancelToken.source();


      let form = new FormData();

      let customName = "";
      if (file.custom_name) customName = file.custom_name.trim();

      // Si el nombre personalizado después del trim tiene contenido usamos ese, y si no pasamos al del fichero
      form.append(
        "original_filename",
        customName != "" ? customName + this.getFileExtension(file) : file.name
      );
      form.append("entity_type", "assignment");
      form.append("entity_id", this.id);

      form.append("project_id", this.project.id)

      return this.store(file, {}).then(async result => {
            // successFul contiene toda la lista de archivos que se han subido
            // para nuestro caso solo consideramos siempre el primero por que enviamos de a uno
            if( result.successful.length > 0 ) {
              let response = result.successful[0].response

              let params = {
                // uuid: response.uuid,
                key: response.body.key,
                // bucket: response.bucket,
                name: file.name,
                content_type: file.type,
                // url: response.url
              }

              form.append("aws_response", JSON.stringify(params));

              let resp = null
              
              resp = await axios.post("/api/v2/" + this.entityType + "/" + this.entityId + "/document", form, {
                  cancelToken: this.cancelRequest.token,
                });
              if (this.dataFolderToCopy.folderId != null || this.dataFolderToCopy.activityId != null && this.copyDocument == true) {
                if (this.dataFolderToCopy.folderId != null ){
                  form.append("folder_id", this.dataFolderToCopy.folderId)   
                }
                
                form.delete("entity_type")
                form.delete("entity_id")
                form.append("project_id", this.project.id)
                
                resp = axios.post("/api/v2/activity/" + this.dataFolderToCopy.activityId + "/document", form, {
                  cancelToken: this.cancelRequest.token,
                });
              }

              return resp && resp.data && resp.data.success ? resp.data.success : false
            }

            // failed contiene todos los archivos que tubieron fallo al subir
            if (result.failed.length > 0) {
              
              result.failed.forEach((file) => {
                console.error(file.error)
              })

              return false
            }
      }).catch((error) => {
        console.log('error: ', error)
        return false
      });

    },

    // CUSTOM VAPOR STORE METHOD
    async store(file, options = null) {
      // verificamos si existe algun archivo en la lista de carga de uppy
      // si existe lo eliminamos
      if( this.keyFileUploadCurrent ) {
        this.uppy.removeFile(this.keyFileUploadCurrent);
      }

      if (typeof options.progress === 'undefined') {
          options.progress = () => {};
      }

      this.keyFileUploadCurrent = this.uppy.addFile({
        name: file.name, // file name
        type: file.type, // file type
        data: file, // file blob
        // meta: {
        //   // optional, store the directory path of a file so Uppy can tell identical files in different directories apart.
        //   relativePath: webkitFileSystemEntry.relativePath,
        // },
        source: 'Local',
        isRemote: false,
      });

      // response.data.extension = file.name.split('.').pop()
      return this.uppy.upload()
    },
    /**
     * Confirma y borra la medición abierta en el modal
     */
    async deleteAssignment() {
      let self = this;

      this.$dialog.confirm({
        title: this.$t("delete_measurement"),
        message: this.$t("delete_measurement_confirm_text", [this.data.name]),
        confirmText: this.$t("delete_measurement"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true;
          let url = "/api/v2/assignment/" + self.id + "/delete";
          const { data } = await axios.post(url);

          if (data.success) {
            self.data = data;

            this.$toast.open({
              message: this.$t("measurement_remove_success"),
              type: "is-success",
              position: "is-top-right",
            });
            self.$emit("reload"); //pide a su componente padre que recargue
            self.hide();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: this.$t("measurement_remove_error"),
              type: "is-danger",
              position: "is-top-right",
            });
          }
        },
      });
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },

    /**
     * Pide la lista de unidades para el proyecto actual
     */
    getProjectUnits() {
      const { data } = axios
        // .get("/api/v2/project/" + this.project.id + "/measurement-units") //eliminar si todo funciona bien
        .get("/api/v2/company/" + this.project.id + "/measurement-units")
        .then((response) => {
          if (response.data && response.data.success) {
            this.unitsList = response.data.units;
          } else {
            this.$notify.error("error_loading_measurement_units_data");
          }
        });
    },

    /**
     * Comprueba si el usuario puede administrar la asignación
     */
    isAdmin() {
      return this.data.user_id == this.user.id;
    },

    /**
     * Comprueba si los datos son de tipo recurso
     */
    isResource() {
      return this.data.type === "App\\Models\\AssignmentType\\Resource";
    },

    /**
     * Comprueba si los datos son de tipo tarea
     */
    isTask() {
      return this.data.type === "App\\Models\\AssignmentType\\Task";
    },

    /**
     * Comprueba si la cantidad ha sido variada por el usuario
     */
    hasExecutedQuantityChanges() {
      return this.form.executed_quantity != this.data.executed_quantity;
    },

    /**
     * Muestra este modal
     */
    show(assignment_id) {
      this.id = assignment_id;
      this.getProjectUnits();
      this.getAssignmentData();
      this.calculation = {
        units: null,
        length: null,
        width: null,
        height: null,
      }
    },

    /**
     * Toma los datos de esta medición de la API
     */
    getAssignmentData() {
      var self = this;
      self.isLoading = true

      let params = { with_order: 1 }
      if (this.isActualProgress) {
        params.with_actual_progress = 1
      }

      const { data } = axios
        .get("/api/v2/assignment/" + this.id, {params: params})
        .then((response) => {
          if (response.data && response.data.success) {
            self.data = response.data.assignment;
            self.form = {
              executed_quantity:
                self.data.executed_quantity == null
                  ? null
                  : String(self.data.executed_quantity),
              executed_quantity_actual:
                self.data.executed_quantity_actual
                  ? self.data.executed_quantity_actual
                  : null,
              observation: "",
              start_date: moment().format('YYYY-MM-DD'),
              end_date: moment().format('YYYY-MM-DD'),
              total: self.data.total_quantity != null ? self.data.total_quantity : self.data.partial,
              progress_type: self.data.progress_type || 'partial',
            };

            //si hay lineas medicion, vemos relativo a qué campo metió avances (miramos la linea mas reciente)
            if (!self.data.lines || !self.data.lines[0]) {
              self.data.lines = []
            }

            // Traer la ultima linea ejecutada (ejecucion normal o real)
            let lineStatus = this.isActualProgress
              ? this.ASSIGNMENTLINESTATUS_ACTUAL_PROGRESS
              : this.ASSIGNMENTLINESTATUS_OK

            let lastLine = self.data.lines.find(line => line.status === lineStatus);
            if (! lastLine) {
              lastLine = {} // establecera (sin warnings) todo a null abajo
            }

            self.lastLine = {
              units: lastLine.units || null,
              length: lastLine.length || null,
              width: lastLine.width || null,
              height: lastLine.height || null
            }

            if (self.data.work_order_id) {
              self.$emit('assigment-load-data-order', { code: self.data.order.code, position: self.data.position })
            }
          } else {
            this.$notify.error("assignment_loading_error");
          }

          self.isLoading = false
        });
    },

    getUnitNameByID(id) {
      return this.unitsList.find((x) => x.id === id);
    },

    /**
     * valores por defecto del formulario
     */
    defaultFormValues() {
      return {
        executed_quantity: "",
        observation: "",
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      };
    },

    /**
     * Oculta este modal
     */
    hide() {
      this.id = null;
      this.data = [];
      this.form = this.defaultFormValues();
      //resetamos archivos
      this.files = [];
    },

    calculateExecutedQuantity() {

      // los campos no introducidos al registrar avance (unid, largo, anc, alt) se toman de los que tiene la medicion
      let executed_quantity = (this.calculation.units || (this.data.units == 0 ? 1 : this.data.units || 1)) *
        (this.calculation.length || (this.data.length == 0 ? 1 : this.data.length || 1)) *
        (this.calculation.width || (this.data.width == 0 ? 1 : this.data.width || 1)) *
        (this.calculation.height || (this.data.height == 0 ? 1 : this.data.height|| 1))

      this.form.executed_quantity = executed_quantity.toFixed(2)
    },

    setUnits( units ) {
      // modificamos un poco para que pueda ir sumando al anterior
      this.calculation.units += units
      this.calculateExecutedQuantity()
    },

    handleInputSwitch(value) {
      this.form.progress_type = 'partial'
      this.$emit('update:registerOverDrawingMode', value)
    }
  },
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
