<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="savePermitting" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">
                    {{ $t('add_permitting') }} / {{ $t('edit_permitting') }}
                </header>
                <div class="modal-card-body_ relative">
                    <div class="border-grey-light border-b border-dashed mb-3">
                        <div class="w-full flex flex-row mb-3">
                            <div class="w-1/2 pr-4">
                                <b-field :label="$t('select_permitting')" :message="templateError"
                                    :type="templateError ? 'is-danger' : ''">
                                    <multiselect v-model="selectTemplate" :options="filteredTemplates" label="code"
                                        track-by="id" :placeholder="$t('select_permitting')" :clear-on-select="false"
                                        :close-on-select="true" :allow-empty="false" :show-labels="false"
                                        :custom-label="templateLabel" @input="onTemplateSelect" />
                                </b-field>
                            </div>
                            <div class="w-1/2 pr-4">
                                <b-field :label="$t('select_activity')" :message="activityError"
                                    :type="activityError ? 'is-danger' : ''">
                                    <treeselect v-model="selectActivity" :options="activities"
                                        placeholder="Selecciona una actividad" :clearable="false"
                                        :disable-branch-nodes="false" @input="onActivitySelect" />
                                </b-field>
                            </div>
                        </div>
                        <div class="w-full flex flex-row mb-3">
                            <div class="w-1/3 pr-4">
                                <b-field :label="$t('request_date')" :message="requestDateError"
                                    :type="requestDateError ? 'is-danger' : ''">
                                    <b-datepicker v-model="form.request_date" :placeholder="$t('select_date')"
                                        :locale="'es-ES'" icon-pack="fas" />
                                </b-field>
                            </div>
                            <div class="w-1/3 pr-4">
                                <b-field :label="$t('obtention_date')" :message="obtentionDateError"
                                    :type="obtentionDateError ? 'is-danger' : ''">
                                    <b-datepicker v-model="form.obtention_date" :placeholder="$t('select_date')"
                                        :locale="'es-ES'" icon-pack="fas" />
                                </b-field>
                            </div>
                            <div class="w-1/3 pr-4">
                                <b-field :label="$t('expiration_date')" :message="expirationDateError"
                                    :type="expirationDateError ? 'is-danger' : ''">
                                    <b-datepicker v-model="form.expiration_date" :placeholder="$t('select_date')"
                                        :locale="'es-ES'" icon-pack="fas" />
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
                <footer class="modal-card-foot_">
                    <div class="w-full inline-flex justify-end">
                        <button class="btn btn-blue" nativeType="submit" :disabled="processingData">
                            {{ $t('save') }}
                        </button>
                        <v-button-processing v-if="processingData" />
                    </div>
                </footer>
            </form>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect'
import moment from 'moment';
import Treeselect from '@riophae/vue-treeselect';
export default {
    props: {
        projectId: {
            type: Number,
            required: true,
        },
    },
    components: {
        Multiselect,
        Treeselect
    },
    data() {
        return {
            canCancel: ['escape', 'x', 'outside'],
            form: {
                permitting_template_id: '',
                activity_id: '',
                request_date: null,
                obtention_date: null,
                expiration_date: null,
            },
            templates: [],
            activities: [],
            isModalActive: false,
            processingData: false,
            filteredActivities: [],
            filteredTemplates: [],
            templateError: null,
            activityError: null,
            selectTemplate: null,
            selectActivity: null,
            requestDateError: null,
            obtentionDateError: null,
            expirationDateError: null,

        }
    },
    computed: {
        hasErrors() {
            return this.requestDateError || this.obtentionDateError || this.expirationDateError;
        }
    },
    watch: {
        "form.request_date"(newVal) {
            if (this.form.obtention_date && newVal > this.form.obtention_date) {
                this.requestDateError = "La fecha de solicitud no puede ser mayor que la fecha de obtención.";
            } else if (this.form.expiration_date && newVal > this.form.expiration_date) {
                this.requestDateError = "La fecha de solicitud no puede ser mayor que la fecha de expiración.";
            } else {
                this.requestDateError = null;
            }
        },
        "form.obtention_date"(newVal) {
            if (this.form.request_date && newVal < this.form.request_date) {
                this.obtentionDateError = "La fecha de obtención no puede ser menor que la fecha de solicitud.";
            } else if (this.form.expiration_date && newVal > this.form.expiration_date) {
                this.obtentionDateError = "La fecha de obtención no puede ser mayor que la fecha de expiración.";
            } else {
                this.obtentionDateError = null;
            }
        },
        "form.expiration_date"(newVal) {
            if (this.form.request_date && newVal < this.form.request_date) {
                this.expirationDateError = "La fecha de expiración no puede ser menor que la fecha de solicitud.";
            } else if (this.form.obtention_date && newVal < this.form.obtention_date) {
                this.expirationDateError = "La fecha de expiración no puede ser menor que la fecha de obtención.";
            } else {
                this.expirationDateError = null;
            }
        }
    },
    methods: {
        show(permitting, activities, templates) {
            this.activities = activities;
            this.templates = templates;
            this.filteredTemplates = templates;
            this.filteredActivities = activities;

            if (permitting) {
                this.form = {
                    id: permitting.id,
                    permitting_template_id: permitting.permitting_template_id,
                    activity_id: permitting.activity_id,
                    request_date: permitting.request_date ? new Date(permitting.request_date) : null,
                    obtention_date: permitting.obtention_date ? new Date(permitting.obtention_date) : null,
                    expiration_date: permitting.expiration_date ? new Date(permitting.expiration_date) : null,
                };

                this.selectTemplate = this.templates.find(template => template.id === permitting.permitting_template_id);

                let selectedActivity = this.findActivityById(this.activities, permitting.activity_id);
                this.selectActivity = selectedActivity ? selectedActivity.id : null;
            } else {
                this.resetForm();
            }
            this.isModalActive = true;
        },
        resetForm() {
            this.form = {
                id: null,
                permitting_template_id: '',
                activity_id: '',
                request_date: null,
                obtention_date: null,
                expiration_date: null,
            }
            this.selectTemplate = null;
            this.selectActivity = null;
        },
        closeModal() {
            this.isModalActive = false;
        },
        onActivitySelect(selected) {
            this.form.activity_id = selected;
        },
        onTemplateSelect(selected) {
            this.form.permitting_template_id = selected.id;
        },
        handleInputChange(field) {
            return (value) => {
                if (field === 'permitting_template_id') {
                    this.filteredTemplates = this.templates.filter(template =>
                        template.name.toLowerCase().includes(value.toLowerCase())
                    );
                } else if (field === 'activity_id') {
                    this.filteredActivities = this.activities.filter(activity =>
                        activity.name.toLowerCase().includes(value.toLowerCase())
                    );
                }
            };
        },
        findActivityById(activities, activityId) {
            for (let activity of activities) {
                if (activity.id === activityId) {
                    return activity;
                }
                if (activity.children && activity.children.length) {
                    const subactivity = this.findActivityById(activity.children, activityId);
                    if (subactivity) return subactivity;
                }
            }
            return null;
        },
        async savePermitting() {
            this.processingData = true;
            let url = `/api/v2/project/${this.projectId}/permitting`;
            if (this.form.id) {
                url = `/api/v2/project/${this.projectId}/permitting/${this.form.id}`;
            }

            // Formatear las fechas antes de enviarlas
            const formattedForm = {
                ...this.form,
                request_date: this.form.request_date ? moment(this.form.request_date).format('YYYY-MM-DD') : null,
                obtention_date: this.form.obtention_date ? moment(this.form.obtention_date).format('YYYY-MM-DD') : null,
                expiration_date: this.form.expiration_date ? moment(this.form.expiration_date).format('YYYY-MM-DD') : null,
            };

            try {

                await axios({
                    method: this.form.id ? 'put' : 'post',
                    url: url,
                    data: formattedForm
                });

                this.$buefy.toast.open({
                    message: this.$t('save_successful'),
                    type: 'is-success'
                });

                this.closeModal();
                this.$emit('reload-list');
                this.resetForm();
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t('save_error'),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
            }
        },
        templateLabel(option) {
            return option.code;
        },

    },
}
</script>

<style lang="scss" scoped>
::v-deep .modal-content {
    overflow: visible !important;
}
</style>