<template>
    <div class="overflow-x-auto">
        <table class="table is-bordered is-striped global-table min-w-full" style="width: 100%;">
            <!-- Encabezado con dos filas -->
            <thead>
                <!-- Fila de grupos -->
                <tr>
                    <!-- Projects y Status con rowspan="2" -->
                    <th rowspan="2" class="bg-gray-200 text-center" style="vertical-align: middle;">
                        {{ $t('project') }}
                    </th>
                    <th rowspan="2" class="bg-gray-200 text-center" style="vertical-align: middle;">
                        {{ $t('status') }}
                    </th>
                    <!-- Grupo "Regional government" con 6 columnas -->
                    <th colspan="6" class="bg-green-lighter text-center">
                        Regional government
                    </th>
                    <!-- Grupo "Affected Townhall" con 5 columnas -->
                    <th colspan="5" class="bg-blue-lighter">
                        Affected Townhall
                    </th>
                    <!-- Otros grupos, por ejemplo 2 columnas -->
                    <th colspan="2" class="bg-orange-lighter text-center">
                        Other
                    </th>
                </tr>
                <!-- Fila con los códigos -->
                <tr>
                    <!-- Regional government codes -->
                    <th class="bg-green-lighter">APP</th>
                    <th class="bg-green-lighter">AAC</th>
                    <th class="bg-green-lighter">AAU</th>
                    <th class="bg-green-lighter">DUP</th>
                    <th class="bg-green-lighter">AAE</th>
                    <th class="bg-green-lighter">RAIPEE</th>
                    <!-- Affected Townhall codes -->
                    <th class="bg-blue-lighter">ICU</th>
                    <th class="bg-blue-lighter">LDM</th>
                    <th class="bg-blue-lighter">LOM</th>
                    <th class="bg-blue-lighter">USE</th>
                    <th class="bg-blue-lighter">LLI</th>
                    <!-- Otros -->
                    <th class="bg-orange-lighter">Contrac Bess</th>
                    <th class="bg-orange-lighter   ">Easement EL</th>
                </tr>
            </thead>

            <!-- Cuerpo de la tabla -->
            <tbody>
                <tr v-for="(project, index) in tableData" :key="index">
                    <td>{{ project.name }}</td>
                    <td>
                        {{ project.average_progress == null ? '-' : project.average_progress + '%' }}
                    </td>
                    <!-- Regional Government columns -->
                    <td class="text-xs " v-html="getColumnData(project, 'APP')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'AAC')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'AAU')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'DUP')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'AAE')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'RAIPEE')"></td>
                    <!-- Affected Townhall columns -->
                    <td class="text-xs" v-html="getColumnData(project, 'ICU')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'LDM')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'LOM')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'USE')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'LLI')"></td>
                    <!-- Otros -->
                    <td class="text-xs" v-html="getColumnData(project, 'Contrac Bess')"></td>
                    <td class="text-xs" v-html="getColumnData(project, 'Easement EL')"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    middleware: 'auth',
    title: 'permitting',
    subtitle: 'Projects Permitting',
    menuOption: '2-1',
    data() {
        return {
            tableData: [],
            isLoading: false,
        };
    },
    computed: {
        ...mapGetters({
            company: 'app/company',
        }),
    },
    mounted() {
        this.loadDatatable();
    },
    methods: {
        async loadDatatable() {
            this.isLoading = true;
            try {
                const { data } = await axios.get(
                    `/api/v2/company/${this.company.id}/projects-permitting-templates`
                );
                this.tableData = data.data;

            } catch (error) {
                throw ('Error loading projects and permitting templates:', error);
            } finally {
                this.isLoading = false;
            }
        },
        formatShortDate(dateStr) {
            if (!dateStr) return '-'
            const date = moment(dateStr)
            return date.isValid() ? date.format('DD/MM/YYYY') : '-'
        },

        getColumnData(project, code) {
            if (!project.permitting_templates) return '-';

            const foundTemplate = project.permitting_templates.find(
                (t) => t.code === code
            );
            if (!foundTemplate) return '-';

            if (!foundTemplate.permittings || foundTemplate.permittings.length === 0) {
                return '-';
            }

            const permitting = foundTemplate.permittings[0];

            const rawObtention = permitting.obtention_date;
            const rawExpiration = permitting.expiration_date;

            const obtentionDate = this.formatShortDate(rawObtention);
            const expirationDate = this.formatShortDate(rawExpiration);

            let colorClass = 'text-black';
            if (
                permitting.activity &&
                permitting.activity.current_start_date &&
                permitting.activity.current_end_date
            ) {
                const start = moment(permitting.activity.current_start_date);
                const end = moment(permitting.activity.current_end_date);
                const obtention = moment(rawObtention);

                if (start.isValid() && end.isValid() && obtention.isValid()) {
                    if (obtention.isBetween(start, end, 'day', '[]')) {
                        colorClass = 'text-blue';
                    } else {
                        colorClass = 'text-red';
                    }
                }
            }

            // Verificar si la expiration_date está a 3 días o menos, o vencida
            let iconHtml = '';
            const now = moment();
            const expiration = moment(rawExpiration);

            if (expiration.isValid()) {
                const diffDays = expiration.diff(now, 'days');
                if (diffDays <= 3 && diffDays >= 0) {
                    // Faltan 3 días o menos
                    iconHtml = `<i class="fas fa-exclamation-triangle text-orange"></i>`;
                } else if (diffDays < 0) {
                    // Ya venció
                    iconHtml = `<i class="fas fa-exclamation-triangle text-red"></i>`;
                }
            }


            return `
                ${iconHtml ? iconHtml + '<br>' : ''}
                <span class="font-semibold ${colorClass}">${obtentionDate}</span>
                <br>
                <span class="font-semibold ${colorClass}">${expirationDate}</span>
            `;
        },


    },
};
</script>

<style lang="scss" scoped>
::v-deep .global-table {
    border: 1px solid black !important;
    border-collapse: collapse;
}

::v-deep .global-table th,
::v-deep .global-table td {
    border: 1px solid black !important;
}


::v-deep .global-table thead th {
    color: black !important;
    text-align: center !important;
        vertical-align: middle !important;
}

::v-deep .global-table td {
    text-align: center !important;
    vertical-align: middle !important;
}
</style>
