<template>
  <GanttV2 v-if="company.alias == 'welink'" />
  <Gantt v-else />
</template>

<script>
import GanttV2 from './gantt-v2.vue';
import Gantt from './gantt.vue'
import { mapGetters } from "vuex";



export default {
  name: 'gantt-redirect',

  components: {
    GanttV2,
    Gantt
  },

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters({
      company: 'app/company'
    }),
  },

  mounted() {
  },

  methods: {

  }
};
</script>
