<template>
  <div>
    <!-- Tabla de chapters-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
    >

    <div class="panel">
      <div v-if="isLoading" class="my-4 text-center">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="flex flex-wrap w-full mt-4">
        <section class="w-full">
          <b-table
            icon-pack="fas"
            :data="pictograms"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            v-if="!isLoading"
            :checkable="false"
            checkbox-position="left"
            :checkbox-type="'is-success'"
            :checked-rows.sync="checkedRows"
            @check="handleCheck">
            <template>
              <!-- <b-table-column field="thumbnail" cell-class="w-6 vertical-center" sortable>
                <template v-slot:header="">{{ $t('thumbnail') }}</template>
                <template v-slot="props">
                  <div class="flex flex-wrap justify-center">
                    <img
                      :src="props.row.file.thumbnail"
                      class="p-1 bg-white border rounded"
                      :alt="props.row.description"
                      onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"
                    />
                  </div>
                </template>
              </b-table-column> -->

              <b-table-column field="code" cell-class="vertical-center" sortable :visible="false">
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="name" cell-class="vertical-center" sortable>
                <template v-slot:header="">{{ $t('name') }} / {{ $t('description') }}</template>
                <template v-slot="props">{{ props.row.name }}<br> {{ props.row.description }}</template>
              </b-table-column>

              <b-table-column field="type" cell-class="vertical-center" sortable>
                <template v-slot:header="">{{ $t('type') }}</template>
                <template v-slot="props">{{ $t(props.row.type ? props.row.type : 'photovoltaic_park')  }}</template>
              </b-table-column>

              <b-table-column field="file" cell-class="w-6 vertical-center" centered :visible="showDownloadFileOriginal">
                <template v-slot:header="">{{ $t('file') + ' original' }}</template>
                <template v-slot="props">
                  <b-tooltip :label="$t('download_document')" position="is-top" v-if="props.row.document_id">
                    <div class="cursor-pointer text-blue-light">
                        <a class="button is-rounded is-link" @click="downloadDoc(props.row.document_id)">
                            <span class="icon">
                              <i class="fas fa-file"></i>
                            </span>
                        </a>
                    </div>
                  </b-tooltip>
                </template>
              </b-table-column>

              <b-table-column field="actions" cell-class="vertical-center" width=100 sortable>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="flex items-center justify-around w-full h-full">

                    <!-- <b-tooltip :label="'editar'" position="is-right" v-if="withEditor && showPdfEditor">
                      <div class="w-8 h-8 overflow-hidden" @click="openEditor(props.row)">
                        <b-icon pack="fas" icon="pen" />
                      </div>
                    </b-tooltip> -->
                    <!-- <b-tooltip :label="$t('pictogram_statuses')" position="is-left">
                        <div @click="onShowInfoPictogramClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="info-circle"/>
                        </div>
                    </b-tooltip> -->

                    <b-tooltip v-if="allowedAction('config_statuses')"
                      :label="$t('pictogram_statuses')" position="is-left">
                        <div @click="onShowConfigStatusesPictogramClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="traffic-light"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip v-if="allowedAction('config_statuses')"
                      :label="$t('pictogram_scheduled_dates')" position="is-left">
                        <div @click="onShowConfigScheduledDatesPictogramClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="calendar-alt"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip v-if="allowedAction('visualize')"
                      :label="$t('view_document')" position="is-left">
                        <div @click="onShowViewerPictogramClicked(props.row)"
                            class="flex cursor-pointer text-sky-300">
                          <b-icon pack="fas" icon="eye"/>
                        </div>
                    </b-tooltip>

                    <b-tooltip v-if="allowedAction('link')"
                      :label="$t('view_assignments')" position="is-left">
                          <div @click="onShowLinkedActivities(props.row)"
                              class="flex cursor-pointer text-sky-300">
                            <b-icon pack="fas" icon="link"/>
                          </div>
                      </b-tooltip>

                    <b-tooltip v-if="allowedAction('delete')"
                      :label="$t('delete')" position="is-left" type="is-danger">
                        <div @click="removePictogram(props.row)"
                            class="flex cursor-pointer text-red">
                          <b-icon pack="fas" icon="trash-alt"/>
                        </div>
                    </b-tooltip>

                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.pictograms.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

          </b-table>
        </section>
        </div>
      </div>
    </div>

    </transition>
  </div>
</template>

<script>
import Form from "vform";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";

export default {
  name: "pictograms-list",

  components: {
    ScaleLoader,
  },

  props: {
    entityId: { type: Number, required: true },
    disabledMenuActions: { type: Boolean, default: false },
    returnSelected: { type: Boolean, default: false },
    showDownloadFileOriginal: { type: Boolean, default: true }, // para mostar o no la columna de descarga de fichero original (en futuro tal vez no sea necesario)
    permittedActions: { type:Object, default() { return {} } } // acciones que se mostraran en la columna acciones por defecto todas
  },

  data() {
      return {
      isLoading: true,
      page: 1,
      perPage: 25,
      total: 0,
      orderField: "reference_code",
      orderDir: "asc",
      currentPage: 1,
      defaultSortDirection: 'asc',
      isPaginationSimple: false,
      isPaginated: true,

      pictograms: [],
      checkedRows: [],
    }
  },

  mounted() {
    this.getProjectPictograms();
  },

  methods: {
    async getProjectPictograms() {
      this.isLoading = true;
      let params = { };

      const { data } = await axios.get(
        "/api/v2/project/" + this.entityId + "/pictograms",
        { params: params }
      );

      if (data && data.success) {
        this.pictograms = data.pictograms;
        this.total = data.pictograms ? data.pictograms.length : 0;
      } else {
        this.$notify.error("error_loading_documents");
      }
      this.isLoading = false;
    },

    reload() {
      this.getProjectPictograms();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    reloadActivities() {
      this.reload()
    },

    async removePictogram(row) {
      this.$dialog.confirm({
        title: this.$t("delete"),
        message: this.$t("delete_generic", [row.name]),
        confirmText: this.$t("delete"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          // this.documentId = null

          this.isLoading = true;
          // let url = "/api/v2/project/" + this.projectId + '/document/' + row.document_id;
          let url = "/api/v2/pictogram/" + row.id + '/delete/';
          const { data } = await axios.post(url);

          if (data.success) {
            this.$toast.open({
              message: this.$t("success_deleting_generic"),
              type: "is-success",
              position: "is-top-right"
            });
            await this.sleep(1000);
            this.reload();
          } else {
            this.isLoading = false;
            // Error
            this.$toast.open({
              message: data.error || this.$t("error_saving"),
              type: "is-danger",
              position: "is-top-right"
            });
          }
        }
      });
    },

    async downloadDoc(document_id) {
      this.$notify.success("starting_download_document");
      let url = "/api/v2/document/" + document_id;

      const {data} = await axios.get(url);

      if (data) {
        window.open(data.data);
      } else {
        this.$notify.error("error_download_document");
      }
    },

    onShowViewerPictogramClicked(row) {
      this.$emit('epc-preview', row)
    },

    onShowConfigStatusesPictogramClicked(row) {
      this.$emit('epc-openstatuses', row)
    },

    onShowConfigScheduledDatesPictogramClicked(row) {
      this.$emit('epc-openscheduleddates', row)
    },

    onShowLinkedActivities(row) {
      this.$emit('epc-openlinked', row)
    },

    handleCheck(checkedList, row) {
      let pictogramsId = checkedList.map((item) => { return item.id })
      this.$emit('epc-openlink', pictogramsId)
    },

    onShowInfoPictogramClicked(row) {
      this.$emit('epc-openinfo', row)
    },

    allowedAction(action) {
      if (Object.keys(this.permittedActions).length === 0)
        return true;

      return this.permittedActions[action] === 'undefined' ? false : this.permittedActions[action]
    }
  }
}
</script>
