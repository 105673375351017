<template>
    <div class="page-with-top-bar">
        <project-admin-quick-buttons hiddenOption="permitting" />
        <div>
            <manage-permitting ref="manage_permitting" :projectId="project_id" :company="company.id"
                @reload-list="handleReloadPermitting" />
        </div>
    </div>
</template>

<script>
import ManagePermitting from "../../components/permittings/manage-permitting.vue";
import ProjectAdminQuickButtons from "·/components/project-admin-quick-buttons";
import { mapGetters } from "vuex";
export default {
    middleware: "auth",
    title: "permittings",
    components: {
        ManagePermitting,
        ProjectAdminQuickButtons,
    },
    data() {
        return {
            project_id: null
        }
    },
    computed: {
        ...mapGetters({
            navData: "app/navData",
            company: "app/company"
        })
    },
    created() {
        this.project_id = this.navData.project_id;
        this.$options.subtitle = ["project_actions_page_subtitle", [this.navData.project_name.toUpperCase()]];
    },
    methods: {
        handleReloadPermitting() {

        }
    },


}
</script>

<style lang="scss" scoped></style>