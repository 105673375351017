<template>
  <div>
    <!-- Filtros -->
    <div class="panel">
      <div class="w-full mr-4 mb-2">
        <b-field>
          <b-select v-model="filterAreaId" :placeholder="$t('area')" icon="sitemap" icon-pack="fas"
            @input="getProjects">
            <option v-for="area in areas" :value="area.id" :key="area.id">
              {{ area.name + " (" + area.reference_code + ")" }}
            </option>
          </b-select>
        </b-field>
      </div>

      <div class="flex items-center mb-2">
        <div class="w-1/2 mr-4">
          <b-field>
            <b-input v-model="filterProjectName" :placeholder="$t('project_name')" type="search" icon="search"
              icon-pack="fas" @keyup.enter.native="getProjects">
            </b-input>
          </b-field>
        </div>
      </div>

      <button class="button is-primary btn-blue" @click="cleanFiltersClicked">{{ $t("clean_filters") }}</button>
    </div>

    <div class="w-full flex justify-between mt-4 below-top-bar">
      <div class="flex"></div>
      <!-- Botón añadir proyectos -->
      <div class="flex float-right">
        <div class="rounded-sm mr-4 mb-4 mt-2">
          <v-button icon="file-alt" @click="createFromTemplateButtonClicked()">{{ $t("add_project_from_template")
            }}</v-button>
        </div>

        <div class="rounded-sm mr-4 mb-4 mt-2">
          <v-button icon="plus" @click="createProjectButtonClicked()">{{ $t("add_project") }}</v-button>
        </div>
      </div>
    </div>

    <!-- Tabla de proyectos -->
    <div class="panel">
      <b-table :data="projects" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
        :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
        :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas">
        <template>
          <b-table-column field="area.name" :label="$t('area')" sortable v-slot="props">
            {{ props.row.area.name }}
          </b-table-column>

          <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="reference" :label="$t('reference')" sortable v-slot="props">
            {{ props.row.reference }} <font style="font-size:0.7em">(#{{ props.row.id }})</font>
          </b-table-column>

          <b-table-column field="current_start_date" :label="$t('start')" class="w-25 vertical-center" sortable centered
            v-slot="props">
            {{ new Date(props.row.current_start_date).toLocaleDateString() }}
          </b-table-column>

          <b-table-column field="current_end_date" :label="$t('end')" class="w-25 vertical-center" sortable centered
            v-slot="props">
            {{ new Date(props.row.current_end_date).toLocaleDateString() }}
          </b-table-column>

          <b-table-column :label="$t('actions')" field="actions" centered width="150" v-slot="props">
            <div class="w-full h-full flex items-center justify-around">
              <b-tooltip :label="$t('edit_project')" position="is-left">
                <div @click="editProjectButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="pencil-alt" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('validations')" position="is-left">
                <div @click="goToValidations(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="project-diagram" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('go_to_departments')" position="is-left">
                <div @click="goToDepartmentsButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="code-branch" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('locations')" position="is-left">
                <div @click="goToLocationsButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="map-marker-alt" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('manage_activities')" position="is-left">
                <div @click="goToImportExportActivities(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="file-excel" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('manage_statuses')" position="is-left">
                <div @click="goToStatuses(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="traffic-light" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('measurements')" position="is-left">
                <div @click="goToAssignmentsButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="tasks" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('issues')" position="is-left">
                <div @click="goToIssuesButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="exclamation-triangle" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('extrafields')" position="is-left">
                <div @click="goToExtrafieldsButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="object-ungroup" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('partners')" position="is-left">
                <div @click="goToPartnersButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="handshake" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('documents_drawings')" position="is-left">
                <div @click="goToDrawingsButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="file-pdf" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('pictograms')" position="is-left">
                <div @click="goToPictogramsButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="solar-panel" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('documents_folders')" position="is-left">
                <div @click="goToDocumentFoldersButtonClicked(props.row)" class="cursor-pointer flex text-blue mr-1">
                  <b-icon pack="fas" icon="folder-plus" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('storehouse')" position="is-left">
                <div @click="goToStorehouseButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="store-alt" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('cost_budget')" position="is-left">
                <div @click="goToCostManagementButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="file-invoice-dollar" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('permitting')" position="is-left">
                <div @click="goToPermittingButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="file-signature" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('technical_specification')" position="is-left">
                <div @click="goToTechnicalSpecificationButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="cogs" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('work_orders')" position="is-left">
                <div @click="goToWorkOrdersButtonClicked(props.row)" class="cursor-pointer flex text-blue">
                  <b-icon pack="fas" icon="chalkboard-user" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('archive_project')" position="is-left">
                <div @click="archiveProjectButtonClicked(props.row.id, props.row.name, props.row.closed_at)"
                  :class="archiveClass(props.row.closed_at)">
                  <b-icon pack="fas" icon="folder-open" />
                </div>
              </b-tooltip>
              <b-tooltip :label="$t('remove_project')" position="is-left" type="is-danger">
                <div @click="deleteProjectButtonClicked(props.row.id, props.row.name)"
                  class="cursor-pointer flex text-red">
                  <b-icon pack="fas" icon="trash-alt" />
                </div>
              </b-tooltip>
            </div>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  middleware: "auth",
  title: "projects_page_title",
  subtitle: "projects_page_subtitle",
  menuOption: "0-2",

  components: {},

  computed: {
    filters() {
      let filters = {
        "order_field": this.orderField,
        "order_dir": this.orderDir
      };
      if (this.filterAreaId) {
        filters["area_id"] = this.filterAreaId;
      }
      if (this.filterProjectName != "") {
        filters["project_name"] = this.filterProjectName;
      }
      return filters;
    }
  },

  data: () => ({
    isLoadingTable: true,
    projects: [],
    areas: [],
    filterAreaId: null,
    filterProjectName: "",
    perPage: 20,
    orderField: "name",
    orderDir: "asc",
    page: 1,
    total: 0
  }),

  created() {
    this.getProjects();
  },

  methods: {
    async getProjects() {
      this.isLoadingTable = true;
      this.projects = [];
      this.areas = [];

      const { data } = await axios.get("/api/v2/admin/projects", { params: this.filters });
      if (data && data.success) {
        this.projects = data.projects;
        this.areas = data.areas;
        this.total = data.projects.length;
        this.isLoadingTable = false;
      }
    },

    onSort(field, order) {
      this.orderField = field;
      this.orderDir = order;
      this.getProjects();
    },

    onPageChange(page) {
      this.page = page;
      this.getProjects();
    },

    cleanFiltersClicked() {
      this.filterAreaId = null;
      this.filterProjectName = "";
      this.getProjects();
    },

    // Botones de acción
    createProjectButtonClicked() {
      this.$store.dispatch("app/updateNavData", {
        area_name: ""
      });
      this.$router.push({ name: "project.create" });
    },

    // Botones de acción
    createFromTemplateButtonClicked() {
      this.$store.dispatch("app/updateNavData", {
        area_name: ""
      });
      this.$router.push({ name: "project.create-from-template" });
    },

    /**
     * Coloca los datos necesarios en NavData para cambiar entre opciones de proyectos
     * @param project
     */
    setQuickButtonsNavData(project) {
      this.$store.dispatch("app/updateNavData", {
        area_name: project.area.name,
        project_id: project.id,
        project_name: project.name
      });
    },

    editProjectButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({
        name: "project.edit",
        params: {
          project_id: project.id,
          area_id: project.area_id
        }
      });
    },
    goToValidations(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({
        name: "project.admin.validations",
        params: {
          project_id: project.id
        }
      });
    },
    goToDepartmentsButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({
        name: "project.departments",
        params: {
          project_id: project.id
        }
      });
    },
    goToLocationsButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({
        name: "project.admin.locations",
        params: {
          project_id: project.id,
          project_name: project.name
        }
      });
    },

    goToWorkOrdersButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({
        name: "project.admin.work-orders",
        params: {
          project_id: project.id
        }
      });
    },

    deleteProjectButtonClicked(projectId, projectName) {
      this.$dialog.confirm({
        title: this.$t("delete_project"),
        message: this.$t("delete_project_confirm_text", [projectName]),
        confirmText: this.$t("delete_project"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          let url = "/api/v2/project/" + projectId + "/delete";
          const { data } = await axios.post(url);
          if (data && data.success) {
            this.$notify.success("success_deleting_project");
            this.getProjects();
          } else {
            this.$notify.error(data.error);
          }
        }
      });
    },
    archiveProjectButtonClicked(projectId, projectName, closedAt) {
      let confirmText = closedAt ? "archive_project_undone_text" : "archive_project_confirm_text";
      let confirmButtonText = closedAt ? "undone_project_archive" : "archive";
      this.$dialog.confirm({
        title: this.$t("archive_project"),
        message: this.$t(confirmText),
        confirmText: this.$t(confirmButtonText),
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          let url = "/api/v2/project/" + projectId + "/toggle_archive";
          const { data } = await axios.post(url);
          if (data && data.success) {
            console.log(data);
            this.$notify.success(data.msg);
            this.getProjects();
          } else {
            this.$notify.error(data.error);
          }
        }
      });
    },
    goToStatuses(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.manage-statuses", params: { project_id: project.id } });
    },

    goToImportExportActivities(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.import-export-activities", params: { project_id: project.id } });
    },

    goToAssignmentsButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.assignments", params: { project_id: project.id } });
    },

    goToIssuesButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.issues", params: { project_id: project.id } });
    },

    goToExtrafieldsButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.extrafields", params: { project_id: project.id } });
    },

    goToPartnersButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({name: "project.admin.partners", params: { project_id: project.id } });
    },

    goToDrawingsButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.drawings", params: { project_id: project.id } });
    },

    goToPictogramsButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.pictograms", params: { project_id: project.id } });
    },

    goToDocumentFoldersButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.document-folders", params: { project_id: project.id } });
    },

    goToStorehouseButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.storehouse", params: { project_id: project.id } });
    },

    goToCostManagementButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.cost-management", params: { project_id: project.id } });
    },

    goToPermittingButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.permittings", params: { project_id: project.id } });
    },

    goToTechnicalSpecificationButtonClicked(project) {
      this.setQuickButtonsNavData(project);
      this.$router.push({ name: "project.admin.technical-specification", params: { project_id: project.id } });
    },

    archiveClass(value) {
      return value ? "cursor-pointer flex text-yellow-dark" : "cursor-pointer flex text-blue";
    }
  }
};
</script>
