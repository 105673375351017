<template>
    <div class="panel">
        <div v-if="isLoading" class="my-4 text-center">
            <scale-loader />
        </div>
        <div v-else>
            <div class="w-full">

                <div class="text-lg font-thin uppercase text-blue-light">{{ $t('tasks') }}</div>
                <!-- Tabla de tareas -->
                <div class="flex items-center my-4 overflow-x-auto lg:overflow-initial-" v-if="tasksList.length">

                    <table class="w-full mb-14">

                        <thead class="bg-blue-lightest">
                        <tr>
                            <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark">Resp.</th>
                            <th class="text-sm font-semibold uppercase text-blue-dark"></th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("task") }}</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("validators") }}</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("status") }}</th>
                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
                        </tr>
                        </thead>

                        <tbody>
                        <template v-for="row in tasksList">

                            <tr class="hover:bg-grey-lightest" :key="row.id">

                                <!-- manager group -->
                                <td class="justify-start w-8 h-full py-2 pl-2 align-middle whitespace-nowrap">
                                    <b-tooltip :label="row.manager_group.name" position="is-right">
                                    <div class="w-8 h-8 p-2 text-white text-xs rounded-full btn btn-gray"
                                        :class="{
                                                'btn-green': row.validation_groups[0].status == 2,
                                                'btn-blue': row.validation_groups[0].status == 1}">
                                                {{ !row.children ? row.manager_group.code : '' }}
                                    </div>
                                   </b-tooltip>

                                </td>

                                <!-- flechita desplegar tareas agrupadas -->
                                <td class="w-5 py-2 align-middle whitespace-nowrap">
                                    <div v-if="row.children && row.children.length" class="flex flex-row text-center align-middle cursor-pointer" @click="displayDetails(row.id)">
                                        <b-tooltip :label="$t('display_grouped_tasks',[row.children.length])" position="is-right">
                                            <b-icon :icon=" (row.id == selectedTask) ? 'chevron-down' : 'chevron-right'" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                        </b-tooltip>
                                    </div>
                                </td>

                                <!-- nombre task -->
                                <td class="justify-start w-48 h-full p-2 align-middle whitespace-nowrap text-sm"
                                  style="white-space: break-spaces;">
                                    <div class="w-48 whitespace-break-spaces">{{ row.name }}</div>
                                    <div v-if="!row.on_time" class="text-xs font-bold text-red">{{ $t('activity_time_status_danger') }}</div>
                                </td>

                                <!-- validadores -->
                                <td class="flex flex-row items-center justify-start w-auto h-full p-2 whitespace-nowrap" >
                                    <span v-for="(group, index) in row.validation_groups" :key="group.id">
                                        <b-tooltip :label="group.group.name" position="is-top">
                                        <div v-if="index > 0" class="w-8 h-8 p-2 mr-1 text-xs text-center text-white rounded-full btn btn-gray"
                                            :class="{
                                                'btn-blue': group.status == 1,
                                                'btn-green': group.status == 2,
                                                'btn-purple': group.status == 3}">

                                                {{ group.group.code }}

                                        </div>
                                        </b-tooltip>
                                    </span>
                                </td>

                                <!-- status -->
                                <td class="vertical-center has-text-centered h-full p-2 w-20 align-middle">
                                    <validation-task-status-label
                                      :status="row.status"
                                      :style-label="'classic'"
                                      :font-size="'0.6'"
                                      class="flex" />
                                </td>

                                <!-- acciones -->
                                <td class="w-10 h-full p-2 align-middle whitespace-nowrap">
                                  <div class="flex flex-row items-center justify-center" v-if="showOptionsMenuTask(row)">

                                    <b-dropdown
                                      :mobile-modal="true"
                                      position="is-bottom-left"
                                      aria-role="list"
                                    >
                                      <template #trigger>
                                        <b-tooltip :label="$t('actions')" position="is-left">
                                          <button type="button" class="h-9 w-9 rounded-full btn-action-primary">
                                            <b-icon icon="ellipsis-vertical" pack="fas"></b-icon>
                                          </button>
                                        </b-tooltip>
                                      </template>

                                      <b-dropdown-item v-if="row.validation_lines && row.validation_lines.length"
                                        aria-role="listitem"
                                        @click="displayHistory(row.id)"
                                        >
                                        <b-icon icon="tasks" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                        {{ $t('display_history',[row.validation_lines.length]) }}
                                      </b-dropdown-item>

                                      <b-dropdown-item v-if="!row.completed && validationStatus != 6 && (row.current_group && row.current_group.group_id == userGroupId) && row.children === null"
                                        aria-role="listitem"
                                        @click="completeTask(row)"
                                        >
                                        <b-icon icon="edit" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon> {{$t('validate_task')}}
                                      </b-dropdown-item>

                                      <b-dropdown-item v-if="row.completed && ((row.manager_group && row.manager_group.id == userGroupId) || isProjectManager || isValidatingUser) && row.children === null"
                                        aria-role="listitem"
                                        @click="reopenTask(row)"
                                        >
                                        <b-icon icon="undo" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon> {{$t('reopen_task')}}
                                      </b-dropdown-item>

                                      <b-dropdown-item v-if="row.status == 5 && (row.current_group && row.current_group.group_id == userGroupId)"
                                        aria-role="listitem"
                                        @click="answerConditional(row)"
                                        >
                                        <b-icon icon="edit" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon> {{$t('answer')}}
                                      </b-dropdown-item>
                                    </b-dropdown>

                                    <!-- <div v-if="row.validation_lines && row.validation_lines.length" class="flex flex-row mr-2 text-center align-middle cursor-pointer" @click="displayHistory(row.id)">
                                        <b-tooltip :label="$t('display_history',[row.validation_lines.length])" position="is-top">
                                            <b-icon icon="tasks" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                        </b-tooltip>
                                    </div> -->

                                    <!-- <div v-if="!row.completed && (row.current_group && row.current_group.group_id == userGroupId) && row.children === null" @click="completeTask(row)" class="text-center cursor-pointer">
                                        <b-tooltip :label="$t('validate_task')" position="is-top">
                                            <b-icon icon="edit" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                        </b-tooltip>
                                    </div> -->

                                    <!-- <div v-if="row.completed && ((row.manager_group && row.manager_group.id == userGroupId) || isProjectManager || isValidatingUser) && row.children === null" @click="reopenTask(row)" class="text-center cursor-pointer">
                                        <b-tooltip :label="$t('reopen_task')" position="is-top">
                                            <b-icon icon="undo" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                        </b-tooltip>
                                    </div> -->

                                    <!-- <div v-if="row.status == 5 && (row.current_group && row.current_group.group_id == userGroupId)" @click="answerConditional(row)" class="text-center cursor-pointer">
                                      <b-tooltip :label="$t('answer')" position="is-top">
                                        <b-icon icon="edit" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                      </b-tooltip>
                                    </div> -->
                                  </div>

                                </td>
                            </tr>

                            <!-- Recorremos las subtareas -->
                            <tr v-if="row.children && row.children.length" :key="'subtask_'+row.id">

                              <td colspan="6" :class="{'hidden': !(row.id == selectedTask)}" style="border-left: 4px solid #e6e8ff">

                                <table class="w-full">

                                <!-- Cabecera Separadora -->
                                <tr class="bg-indigo-lightest">
                                    <td colspan="5" class="p-4">
                                    <div class="flex justify-start w-auto h-full align-middle">
                                        <b-icon icon="list" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                        <span class="ml-2 text-xs font-semibold uppercase text-blue">{{ row.children.length }} tareas agrupadas</span>
                                    </div>
                                    </td>
                                </tr>

                                <!-- Cabeceras de tabla -->
                                <tr class="bg-blue-lightest">
                                    <th class="p-2 text-sm font-semibold text-center uppercase text-blue-dark">Resp.</th>
                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("task") }}</th>
                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("validators") }}</th>
                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t("status") }}</th>
                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark"></th>
                                </tr>

                                <!-- Subtareas -->
                                <tbody>

                                    <template v-for="rowSub in row.children">

                                    <tr class=" bg-grey-lighter hover:bg-grey-light" v-if="true" :key="rowSub.id">

                                        <!-- SUB. Manager group -->
                                        <td class="justify-start w-8 h-full py-2 pl-2  align-middle">
                                            <b-tooltip :label="rowSub.manager_group.name" position="is-top">
                                                <div class="w-8 h-8 p-2 text-white text-xs rounded-full btn btn-gray"
                                                    :class="{
                                                            'btn-green': rowSub.validation_groups[0].status == 2,
                                                            'btn-blue': rowSub.validation_groups[0].status == 1}">
                                                            {{ rowSub.manager_group.code }}
                                                </div>
                                            </b-tooltip>
                                        </td>

                                        <!-- SUB. Nombre task -->
                                        <td class="p-2 w-48 align-middle">
                                            {{ rowSub.name }}
                                            <div v-if="!rowSub.on_time" class="text-xs font-bold text-red">{{ $t('activity_time_status_danger') }}</div>
                                        </td>

                                        <!-- SUB. Validadores -->
                                        <td class="p-2 align-middle">
                                            <span v-for="(group, index) in rowSub.validation_groups" :key="group.id">
                                                <b-tooltip :label="group.group.name" position="is-top">
                                                <div v-if="index > 0" class="w-8 h-8 p-2 mr-1 text-xs text-center text-white rounded-full btn btn-gray"
                                                    :class="{
                                                        'btn-blue': group.status == 1,
                                                        'btn-green': group.status == 2}">

                                                        {{ group.group.code }}

                                                </div>
                                                </b-tooltip>
                                            </span>
                                        </td>

                                        <!-- status -->
                                        <td class="p-2 w-20 align-middle">
                                            <validation-task-status-label :status="rowSub.status" class="flex" />
                                        </td>

                                        <!-- Opciones -->
                                        <td class="w-10 h-full p-2 align-middle">
                                          <div class="flex flex-row justify-center item-center" v-show="showOptionsMenuSubTask(rowSub)">
                                            <b-dropdown
                                              :mobile-modal="true"
                                              position="is-bottom-left"
                                              aria-role="list"
                                            >
                                              <template #trigger>
                                                <b-tooltip :label="$t('actions')" position="is-left">
                                                  <button type="button" class="h-9 w-9 rounded-full btn-action-primary">
                                                    <b-icon icon="ellipsis-vertical" pack="fas"></b-icon>
                                                  </button>
                                                </b-tooltip>
                                              </template>

                                              <b-dropdown-item v-if="rowSub.validation_lines && rowSub.validation_lines.length"
                                                aria-role="listitem"
                                                @click="displaySubHistory(rowSub.id)"
                                                >
                                                <b-icon icon="tasks" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                                {{ $t('display_history',[rowSub.validation_lines.length]) }}
                                              </b-dropdown-item>

                                              <b-dropdown-item v-if="!rowSub.completed && validationStatus != 6  && (rowSub.current_group && rowSub.current_group.group_id == userGroupId)"
                                                aria-role="listitem"
                                                @click="completeTask(rowSub)"
                                                >
                                                <b-icon icon="edit" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon> {{$t('validate_task')}}
                                              </b-dropdown-item>

                                              <b-dropdown-item v-if="rowSub.completed && ((rowSub.manager_group && rowSub.manager_group.id == userGroupId) || isProjectManager)"
                                                aria-role="listitem"
                                                @click="reopenTask(rowSub)"
                                                >
                                                <b-icon icon="undo" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon> {{$t('reopen_task')}}
                                              </b-dropdown-item>
                                            </b-dropdown>

                                            <!-- <div v-if="rowSub.validation_lines && rowSub.validation_lines.length"
                                              class="flex flex-row mr-2 text-center align-middle cursor-pointer" @click="displaySubHistory(rowSub.id)">
                                                <b-tooltip :label="$t('display_history',[rowSub.validation_lines.length])" position="is-left">
                                                    <b-icon icon="tasks" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                                </b-tooltip>
                                            </div> -->

                                            <!-- <div v-if="!rowSub.completed && validationStatus != 6  && (rowSub.current_group && rowSub.current_group.group_id == userGroupId)"
                                              @click="completeTask(rowSub)" class="text-center cursor-pointer">
                                                <b-tooltip :label="$t('validate_task')" position="is-top">
                                                    <b-icon icon="edit" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                                </b-tooltip>
                                            </div> -->

                                            <!-- <div v-if="rowSub.completed && ((rowSub.manager_group && rowSub.manager_group.id == userGroupId) || isProjectManager)"
                                              @click="reopenTask(rowSub)" class="text-center cursor-pointer">
                                                <b-tooltip :label="$t('reopen_task')" position="is-top">
                                                    <b-icon icon="undo" pack="fas" size="is-small" class="mr-1 text-blue"></b-icon>
                                                </b-tooltip>
                                            </div> -->
                                          </div>

                                        </td>

                                    </tr>



                                    <!-- SUB. historial de acciones -->
                                    <tr :key="'subhistory_'+rowSub.id">
                                        <td colspan="5" :class="{'hidden': !(rowSub.id == selectedSubHistory)}" >
                                            <table class="w-full">

                                                <tr class="bg-indigo-lightest">

                                                    <td colspan="4" class="p-4">
                                                        <div class="flex justify-start w-auto h-full align-middle">
                                                        <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                                        <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr class="bg-blue-lightest">
                                                    <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('group') }}</th>
                                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                                                    <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                                                    <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('days') }}</th>
                                                    <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                                                </tr>

                                                <tbody>
                                                    <template v-for="rowSubHistory in rowSub.validation_lines">
                                                    <tr :key="rowSubHistory.id" class="hover:bg-grey-lightest">

                                                        <td align="center" class="justify-start w-auto h-full p-4">
                                                            <div class="text-xs text-center">
                                                            <!--
                                                            <l-timeago
                                                                :since="rowSubHistory.created_at"
                                                                :locale="locale"
                                                                class="my-1 text-xs font-semibold text-blue-light"
                                                            />
                                                            -->
                                                                <div class="my-1 text-xs font-semibold text-blue-light">
                                                                    {{dateToText(rowSubHistory.created_at)}}
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td align="center" class="p-2">
                                                            <span class="text-xs">{{rowSubHistory.group}}</span>
                                                        </td>

                                                        <td class="p-2">

                                                            <div class="flex flex-row items-center justify-start w-auto h-full">
                                                                <div class="w-8 h-8 rounded-full">
                                                                    <img class="w-8 h-8 rounded-full" :src="rowSubHistory.user.avatar"/>
                                                                </div>
                                                                <div class="flex flex-col ml-2">
                                                                    <div class="text-xs">{{ rowSubHistory.user.fullname}}</div>
                                                                    <div class="text-xs text-grey-dark">{{ rowSubHistory.user.area}}</div>
                                                                </div>
                                                            </div>

                                                        </td>

                                                        <td align="center" class="h-full p-4">
                                                            <validation-task-status-label :status="rowSubHistory.status" class="w-full" />
                                                        </td>

                                                        <td align="center" class="p-2">
                                                            <span class="text-xs">{{rowSubHistory.days}}</span>
                                                        </td>

                                                        <td class="w-2/5 p-2">
                                                            <div class="flex flex-col">
                                                            <div class="text-xs" v-html="rowSubHistory.observation"></div>
                                                            <div class="flex flex-row">

                                                                <template v-for="rowDocument in rowSubHistory.documents">
                                                                <div @click="showPreview(rowDocument.version || rowDocument)" :key="rowDocument.document_id" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                                                    <b-tooltip :label="rowDocument.version && rowDocument.version.name!=rowDocument.name ? rowDocument.version.name + ' (' +rowDocument.name+')' : rowDocument.name" position="is-top" >
                                                                        <div v-if="!isImage(rowDocument.extension)">
                                                                            <img class="w-12 h-12 rounded-lg" :src="'https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'"/>
                                                                        </div>
                                                                        <div v-else>
                                                                            <img class="w-12 h-12 rounded-lg" :src="rowDocument.version ? rowDocument.version.file.thumbnail : rowDocument.file.thumbnail" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"/>
                                                                        </div>
                                                                    </b-tooltip>
                                                                </div>
                                                                </template>
                                                            </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                    </template>
                                                </tbody>

                                            </table>
                                    </td>
                                    </tr>

                                    </template>

                                </tbody>

                                </table>

                              </td>

                            </tr>

                            <!-- fin -->



                            <!-- historial de acciones -->
                            <tr :key="'history_' + row.id">
                                <td colspan="6" :class="{'hidden': !(row.id == selectedHistory)}"  style="border-left: 4px solid #e6e8ff">
                                    <table class="w-full">

                                        <tr class="bg-indigo-lightest">

                                            <td colspan="6" class="p-4">
                                                <div class="flex justify-start w-auto h-full align-middle">
                                                <b-icon icon="history" pack="fas" size="is-small" class="ml-1 text-blue"></b-icon>
                                                <span class="ml-2 text-xs font-semibold text-blue">{{ $t('resource_history_header') }}</span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="bg-blue-lightest">
                                            <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('date') }}</th>
                                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('group') }}</th>
                                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('user') }}</th>
                                            <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('action') }}</th>
                                            <th align="center" class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('days') }}</th>
                                            <th class="p-2 text-sm font-semibold uppercase text-blue-dark">{{ $t('observations') }}</th>
                                        </tr>

                                        <tbody>
                                            <template v-for="rowHistory in row.validation_lines">
                                            <tr :key="rowHistory.id" class="hover:bg-grey-lightest">

                                                <td align="center" class="justify-start w-auto h-full p-4">
                                                    <div class="text-xs text-center">
                                                    <!--
                                                    <l-timeago
                                                        :since="rowHistory.created_at"
                                                        :locale="locale"
                                                        class="my-1 text-xs font-semibold text-blue-light"
                                                    />
                                                    -->
                                                    <div class="my-1 text-xs font-semibold text-blue-light">
                                                        {{dateToText(rowHistory.created_at)}}
                                                    </div>
                                                    </div>
                                                </td>

                                                <td align="center" class="p-2">
                                                    <span class="text-xs">{{rowHistory.group}}</span>
                                                </td>

                                                <td class="p-2">

                                                    <div class="flex flex-row items-center justify-start w-auto h-full">
                                                        <div class="w-8 h-8 rounded-full">
                                                            <img class="w-8 h-8 rounded-full" :src="rowHistory.user.avatar"/>
                                                        </div>
                                                        <div class="flex flex-col ml-2">
                                                            <div class="text-xs">{{ rowHistory.user.fullname}}</div>
                                                            <div class="text-xs text-grey-dark">{{ rowHistory.user.area}}</div>
                                                        </div>
                                                    </div>

                                                </td>

                                                <td align="center" class="h-full p-4">
                                                    <validation-task-status-label :status="rowHistory.status" class="w-full" />
                                                </td>

                                                <td align="center" class="p-2">
                                                    <span class="text-xs">{{rowHistory.days}}</span>
                                                </td>

                                                <td class="w-2/6 p-2">
                                                    <div class="flex flex-col">
                                                    <div class="text-xs" v-html="rowHistory.observation"></div>
                                                    <div class="flex flex-row">

                                                        <template v-for="rowDocument in rowHistory.documents">
                                                          <div @click="showPreview(rowDocument.version || rowDocument)" :key="rowDocument.document_id" class="w-12 h-12 m-1 rounded-lg shadow cursor-pointer bg-grey">
                                                              <b-tooltip :label="rowDocument.version && rowDocument.version.name!=rowDocument.name ? rowDocument.version.name + ' (' +rowDocument.name+')' : rowDocument.name" position="is-top" >
                                                                <div v-if="!isImage(rowDocument.extension)">
                                                                    <img class="w-12 h-12 rounded-lg" :src="'https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/document.jpeg'"/>
                                                                </div>
                                                                 <div v-else>
                                                                    <img class="w-12 h-12 rounded-lg" :src="rowDocument.version ? rowDocument.version.file.thumbnail : rowDocument.file.thumbnail" onerror="this.src='https://epc-tracker-assets.s3.eu-west-3.amazonaws.com/public/thumb.jpeg'"/>
                                                                </div>
                                                              </b-tooltip>

                                                          </div>
                                                        </template>
                                                    </div>
                                                    </div>
                                                </td>

                                            </tr>
                                            </template>
                                        </tbody>

                                    </table>
                                </td>
                            </tr>



                        </template>
                        </tbody>
                    </table>
                </div>
                <div v-else class="flex flex-row justify-between text-sm uppercase align-middle text-blue-light">

                    <span>{{ $t("no_results") }}</span>

                </div>

            </div>
        </div>

        <!-- Modal para Editar tarea -->
        <validationtask-completion-modal
            ref="validationtask_completion_modal"
            :flow-id="validationId"
            :canManage="true"
            :validableName="validableName"
            :validableParentCode="validableParentCode"
            @reload="() => reload()"
        />

        <!-- Modal para Reabrir tarea -->
        <validationtask-reopen-modal
            ref="validationtask_reopen_modal"
            :flow-id="validationId"
            :canManage="true"
            :validableName="validableName"
            @reload="() => reload()"
        />

           <!-- Modal para PREVIEW -->
    <validationtask-document-preview
        :entity-id="validationId"
        entity-type="validation"
        ref="previewModal"
        @reload="() => reload()"
    />

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import validationtaskCompletionModal from "·/components/validations/validationtask-completion-modal";
import validationTaskStatusLabel from '·/components/validations/validation-task-status-label'
import validationtaskDocumentPreview from "·/components/validations/validationtask-document-preview";
import validationtaskReopenModal from "·/components/validations/validationtask-reopen-modal";

import moment from "moment";
import EventBus from "~/plugins/bus";
import { format } from 'date-fns'

export default {
    name: "validation-tasks",

    components: {
        ScaleLoader,
        validationtaskCompletionModal,
        validationTaskStatusLabel,
        validationtaskDocumentPreview,
        validationtaskReopenModal
    },

    props: {
        validationId: { type: Number, required: true },
        validableName: { type: String, required: true },
        validableParentCode: { type: String, required: false, default: '' },
        validationStatus: { type: Number, required: true }, // 6 = closed
        userGroupId: { type: Number, required: true },
        isProjectManager: { type: Boolean, required: true },
        isValidatingUser: { type: Boolean, default: false } // si es usuario validador
    },

    data: () => ({
        selectedTask: undefined,
        selectedHistory: undefined,
        selectedSubHistory: undefined,
        isLoading: true,
        tasksList: [],
    }),

    computed: {
        ...mapGetters({
        locale: "lang/locale",
        // user: "auth/user",
        }),
    },

    watch: {
        validationId(id) {
            if (id > 0) {
                this.getList();
            }
        },
    },

    mounted() {
        let self = this;
        EventBus.$on("refreshValidationTasks", function () {
            self.isLoading = true;
            self.getList();
        });

        EventBus.$on("refreshValidationDocumentsNotifications", function(e) {
            self.isLoading = true;
            self.getList();
                    });
    },

    created() {
        this.getList();
    },

    beforeDestroy(){
      EventBus.$off("refreshValidationTasks");
      EventBus.$off("refreshValidationDocumentsNotifications");
    },

    methods: {

        async getList() {
            let args = {
                flow_id: this.validationId,
                with_documents_selectables: 1
            };

            let url = "/api/v2/validation/" + this.validationId + "/tasks";
            await axios.get(url, { params: args })
                .then(response => {

                    if (response.data && response.data.success) {

                        this.tasksList = response.data.tasks;

                        // this.documents = data.documents || [];
                        this.isLoading = false;
                    } else {
                        this.$notify.error("error_retrieving_data");
                    }
                })
                .catch((error) => {
                    this.$notify.error("Error obteniendo tareas");
                    // console.log(error);
                });
        },

        // abre modal de completar tarea y subir doc
        completeTask(task) {
            let firstGroup = task.validation_groups[0]
            let isFirstGroup = (task.current_group.group_id == firstGroup.group.id)
            let lastGroup = task.validation_groups[task.validation_groups.length - 1]
            let isLastGroup = (task.current_group.group_id == lastGroup.group.id)
            this.$refs.validationtask_completion_modal.show(task, isFirstGroup, isLastGroup);
        },

        // abre modal de reabrir tarea
        reopenTask(task) {
            this.$refs.validationtask_reopen_modal.show(task);
        },

        dateToText(date) {
            let dateObject = new Date(date);
            return format(dateObject, 'dd/MM/yyyy HH:mm')
        },

      answerConditional(task) {
        let lastGroup = task.validation_groups[task.validation_groups.length - 1]
        let isLastGroup = (task.last_group.group_id == lastGroup.group.id)
        this.$refs.validationtask_completion_modal.show(task, false, isLastGroup);
      },


        showPreview(document) {
            this.$refs.previewModal.show(document);
            if (document.exif) {
                EventBus.$emit("load-locations", document.exif);
            }
        },

        displayDetails(rowId){
            this.selectedTask = (this.selectedTask == rowId) ? undefined : rowId;
        },

        displayHistory(rowId){
            this.selectedHistory = (this.selectedHistory == rowId) ? undefined : rowId;
        },

        displaySubHistory(rowId){
            this.selectedSubHistory = (this.selectedSubHistory == rowId) ? undefined : rowId;
        },

        isImage(extension) {
          let valid_extensions = ['png', 'jpg', 'jpeg']
          return valid_extensions.includes(extension)
        },

        // TODO: METHODOS a refactorizar luego quitarlos
        showOptionsMenuTask(task) {
          return [
            // History
            {
              name: 'task_history',
              visible: task.validation_lines && task.validation_lines.length,
            },
            // Completar tarea
            {
              name: 'task_complete',
              visible: !task.completed && this.validationStatus != 6 && (task.current_group && task.current_group.group_id == this.userGroupId) && task.children === null,
            },

            // Reabrir tarea
            {
              name: 'task_reopen',
              visible: task.completed && ((task.manager_group && task.manager_group.id == this.userGroupId) || this.isProjectManager || this.isValidatingUser) && task.children === null,
            },
            // Descargar carpeta tipo actividad
            {
              name: 'task_answer',
              visible: task.status == 5 && (task.current_group && task.current_group.group_id == this.userGroupId),
            },
          ].filter(el => el.visible) .length > 0
        },

        showOptionsMenuSubTask(subtask) {
          return [
            // History
            {
              name: 'task_history',
              visible: subtask.validation_lines && subtask.validation_lines.length,
            },
            // Completar tarea
            {
              name: 'task_complete',
              visible: !subtask.completed && this.validationStatus != 6  && (subtask.current_group && subtask.current_group.group_id == this.userGroupId),
            },

            // Reabrir tarea
            {
              name: 'task_reopen',
              visible: subtask.completed && ((subtask.manager_group && subtask.manager_group.id == this.userGroupId) || this.isProjectManager),
            },
          ].filter(el => el.visible) .length > 0
        },

        // FIN METHODOS TO REFACTOR

    }
}
</script>

<style>
.validatedGroup {
    border: 3px solid #1f9d55;
}
.currentGroup {
    border: 3px solid #ff9130;
}

.btn-action-primary {
  background-color: rgba(85, 119, 187, 0.133);
  color: rgb(51, 85, 136);
}
.btn-action-primary:hover,
.btn-action-primary:active {
  background-color: #358;
  color: white;
}

@media (min-width: 1024px) {
  .lg\:overflow-initial {
    overflow: initial;
  }
}
</style>
