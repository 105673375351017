<template>
  <div  class="absolute top-0 flex flex-col justify-center h-full overflow-y-auto lg:sticky lg:w-1/6"
    style="background-color:#57b; z-index: 60;">

    <div class="h-screen" style="background-color:#57b;">
    <!-- LOGO -->
    <router-link
      id="logo"
      :to="{ name: 'group.select' }"
      class="block p-4 has-text-centered"
      style="height:65px"
    >
      <div class="h-12 logoOFF" :style="'-webkit-mask: url('+logoUrl+') no-repeat 50% 50%; mask: url('+logoUrl+') no-repeat 50% 50%; background-color: white'"></div>
    </router-link>
    <!-- SIDEBAR menu -->
    <sidebarmenu
      :data="menuSuperAdmin"
      v-if="this.user && this.user.root"
      @epc-show-sidebar="_HandleShowSidebar"
    ></sidebarmenu>
    <sidebarmenu
      :data="menuDataAdminArea"
      v-if="this.user && this.user.areas.can_admin"
      @epc-show-sidebar="_HandleShowSidebar"
    ></sidebarmenu>
    <sidebarmenu
      :data="menuDataAdminProject"
      v-if="
        this.user && !this.user.areas.can_admin && this.user.projects.can_admin
      "
      @epc-show-sidebar="_HandleShowSidebar"
    ></sidebarmenu>
    <sidebarmenu
      :data="menuDataOthers"
      v-if="
        this.user && !this.user.areas.can_admin && !this.user.projects.can_admin
      "
      @epc-show-sidebar="_HandleShowSidebar"
    ></sidebarmenu>

  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Sidebarmenu from "./SidebarMenu";

export default {
  components: {
    Sidebarmenu
  },

  data: () => ({
    appName: window.config.appName,
    menuDataAdminArea: [
      {
        sectionType: "menu",
        sectionTitle: "administrator",
        icon: null,
        items: [
          //{ title: 'home', icon: 'tachometer-alt', url: 'admin.home' },
          { title: "areas", icon: "sitemap", url: "admin.areas" },
          { title: "groups", icon: "folder", url: "admin.groups" },
          { title: "projects", icon: "clipboard-list", url: "admin.projects" },
          { title: "users", icon: "users", url: "admin.users" },
          { title: "collaborators", icon: "handshake", url: "admin.partners" },
          { title: "validations", icon: "project-diagram", url: "admin.validations" },
          { title: "project_templates", icon: "file-alt", url: "admin.project-templates" },
          { title: "measurement_units", icon: "ruler-combined", url: "admin.measurement-units" },
          //{ title: "livemap", icon: "map", url: "admin.livemap" }
          // { title: 'account_and_invoicing', icon: 'sliders-h', url: null },
        ]
      },
      {
        sectionType: "spacer"
      },
       {
        sectionType: "menu",
         sectionTitle: "corporate_zone",
        icon: "play-circle",
         items: [
           { title: "incidents", icon: "exclamation-triangle", url: "corporate.issues" },
           { title: "permitting", icon: "file-signature", url: "corporate.permittings" },
         ]
       },
      {
        sectionType: "menu",
        sectionTitle: "work_zone",
        icon: "play-circle",
        items: []
      },     
    ],
    menuDataAdminProject: [
      {
        sectionType: "menu",
        sectionTitle: "administrator",
        icon: null,
        items: [{ title: "projects", icon: "clipboard-list", url: "admin.projects" }]
      },
      {
        sectionType: "spacer"
      },
      {
        sectionType: "menu",
        sectionTitle: "work_zone",
        icon: "play-circle",
        items: []
      }
    ],
    menuDataOthers: [
      {
        sectionType: "menu",
        sectionTitle: "",
        icon: null,
        items: []
      },
      {
        sectionType: ""
      },
      {
        sectionType: "menu",
        sectionTitle: "work_zone",
        icon: "play-circle",
        items: []
      }
    ],
    workZoneMenu: [
      { title: "select_group_menu", icon: "th", url: "group.select" },
      { title: "project_menu", icon: "tv", url: "project.home" },
      { title: "activities_planning", icon: "lightbulb", url: "project.activities"
        // subitems: [
        //   { title: 'submenu_1', url: null },
        // ]
      },
      { title: "document_manager", icon: "folder-plus", url: "project.activities.folders" },
      { title: "validations", icon: "project-diagram", url: "project.validations" },
      { title: "technical_specification", icon: "cogs", url: "project.technical-specifications" },
      { title: "measurements", icon: "list", url: "project.measurements" },
      { title: "documents", icon: "file", url: "project.documents" },
      { title: "drawings", icon: "file-pdf", url: "project.drawings" },
      { title: "incidents", icon: "exclamation-triangle", url: "project.issues" },
      { title: "org_chart", icon: "code-branch", url: "project.orgchart" },
      { title: "storehouse", icon: "store-alt", url: "project.storehouse" },
      { title: "bim_module", icon: "file-invoice", url: "project.bim" },
      { title: "locations", icon: "map-marker-alt", url: "project.locations" },
      { title: "permitting", icon: "file-signature", url: "permitting.workzone" },
      // { title: 'daily_report', icon: 'clipboard', url: null },
    ],
    selectProjectMenu: [
      { title: "select_project_menu", icon: "th", url: "group.select" }
    ],

    menuSuperAdmin: [
      {
        sectionType: "menu",
        sectionTitle: "superadmin",
        icon: null,
        items: [
          //{ title: 'home', icon: 'tachometer-alt', url: 'admin.home' },
          { title: "companies", icon: "building", url: "panel.companies" },
        ]
      }
    ],

    version: window.config.version,
    logoUrl: window.config.assetUrl ? window.config.assetUrl + '/img/logo.svg' : '/img/logo.svg',
  }),

  computed: mapGetters({
    user: "auth/user",
    project: "app/project",
    authenticated: "auth/check"
  }),

  watch: {
    project() {
      this.changeWorkZoneMenu();
    }
  },

  mounted() {
    this.changeWorkZoneMenu();

    // mostrar opcion "administrador de subcontratas" si user no es administrador de areas ni proyectos, pero si de alguna subcontrata
    if (this.user && this.user.partners.can_admin) {
      this.menuDataOthers[0].sectionTitle = "administrador"
      this.menuDataOthers[0].items.unshift({ title: "collaborators", icon: "handshake", url: "admin.partners" })
      this.menuDataAdminProject[0].sectionTitle = "administrador"
      this.menuDataAdminProject[0].items.push({ title: "collaborators", icon: "handshake", url: "admin.partners" })

    }
  },

  methods: {
    changeWorkZoneMenu() {
      if (this.project !== null) {
        let emptyProject =
          Object.keys(this.project).length === 0 &&
          this.project.constructor === Object;
        if (this.user.areas.can_admin) {
          this.menuDataAdminArea[3].items = emptyProject
            ? this.selectProjectMenu
            : this.workZoneMenu;
        } else if (!this.user.areas.can_admin && this.user.projects.can_admin) {
          this.menuDataAdminProject[2].items = emptyProject
            ? this.selectProjectMenu
            : this.workZoneMenu;
        } else if (
          !this.user.areas.can_admin &&
          !this.user.projects.can_admin
        ) {
          this.menuDataOthers[2].items = emptyProject
            ? this.selectProjectMenu
            : this.workZoneMenu;
        }
      } else {
        //Mostramos la opción de "Seleccionar proyecto..."
        if (this.user && this.user.areas.can_admin) {
          this.menuDataAdminArea[3].items = this.selectProjectMenu;
        } else if (this.user && !this.user.areas.can_admin && this.user.projects.can_admin) {
          this.menuDataAdminProject[2].items = this.selectProjectMenu;
        } else if (
          this.user &&
          !this.user.areas.can_admin &&
          !this.user.projects.can_admin
        ) {
          this.menuDataOthers[2].items = this.selectProjectMenu;
        }
      }
    },

    _HandleShowSidebar(e) {
      this.$emit('epc-show-sidebar', e)
    }
  }
};
</script>
