<template>
    <div class="overflow-x-auto panel">
        <table class="global-table min-w-full border border-gray-300">
            <thead>
                <tr>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">Document</th>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">%</th>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">Status</th>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">Request Date</th>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">Obtention Date</th>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">Expiration Date</th>
                    <th class="px-4 py-2 border border-gray-300 bg-gray-100">
                        <i class="fas fa-exclamation-triangle"></i>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="template in templates" :key="template.id" class="hover:bg-gray-50 font-bold">
                    <td class="border  px-4 py-2" :class="getColorByCode(template.code)">
                        {{ template.code }}
                    </td>

                    <td class="border border-gray-300 px-4 py-2" :class="getObtentionColorClass(template)">
                        <span v-if="template.permitting && template.permitting.activity">
                            {{ template.permitting.activity.progress }} %
                        </span>
                        <span v-else>
                            -
                        </span>
                    </td>
                    <td class="border border-gray-300 px-4 py-2" :class="getObtentionColorClass(template)">
                        {{ getStatus(template) }}
                    </td>

                    <!-- Request Date -->
                    <td class="border border-gray-300 px-4 py-2" :class="getObtentionColorClass(template)">
                        {{ formatShortDate(template.permitting ? template.permitting.request_date : null) }}
                    </td>

                    <!-- Obtention Date -->
                    <td class="border border-gray-300 px-4 py-2" :class="getObtentionColorClass(template)">
                        {{ formatShortDate(template.permitting ? template.permitting.obtention_date : null) }}
                    </td>

                    <!-- Expiration Date -->
                    <td class="border border-gray-300 px-4 py-2" :class="getObtentionColorClass(template)">
                        {{ formatShortDate(template.permitting ? template.permitting.expiration_date : null) }}
                    </td>

                    <!-- Ícono de advertencia -->
                    <td class="border border-gray-300 px-4 py-2 text-center">
                        <span v-html="getWarningIcon(template)"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    middleware: "auth",
    title: "permittings",
    menuOption: "3-14",
    data() {
        return {
            templates: [],
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            project: 'app/project',
            company: 'app/company',
            visualConfig: 'app/visualConfig',
            mobile: 'device/mobile'
        }),
    },
    async mounted() {
        await this.loadTemplates();
    },
    methods: {
        async loadTemplates() {
            try {
                const { data } = await axios.get(`/api/v2/company/${this.company.id}/project/${this.project.id}/permitting-templates`);
                this.templates = data;
            } catch (error) {
                throw('Error loading templates:', error);
            }
        },
        formatShortDate(dateStr) {
            if (!dateStr) return '-';
            const date = moment(dateStr);
            return date.isValid() ? date.format('DD/MM/YY') : '-';
        },
        getStatus(template) {
            const p = template.permitting;
            if (!p) return this.$t('initial');

            if (p.request_date && !p.obtention_date && !p.expiration_date) {
                return this.$t('request');
            }

            if (p.obtention_date && p.expiration_date) {
                const expiration = moment(p.expiration_date, 'YYYY-MM-DD');
                if (expiration.isValid() && expiration.isBefore(moment())) {
                    return this.$t('expired');
                }
                return this.$t('valid');
            }

            return this.$t('initial');
        },
        getObtentionColorClass(template) {
            const p = template.permitting;
            if (!p || !p.activity || !p.activity.current_start_date || !p.activity.current_end_date) {
                return '';
            }
            const obt = moment(p.obtention_date);
            const start = moment(p.activity.current_start_date);
            const end = moment(p.activity.current_end_date);

            if (!obt.isValid() || !start.isValid() || !end.isValid()) {
                return '';
            }

            // Dentro del rango => azul
            if (obt.isBetween(start, end, 'day', '[]')) {
                return 'text-blue';
            }
            // Fuera del rango => naranja
            return 'text-red';
        },
        getColorByCode(code) {
            const greenCodes = ['APP', 'AAC', 'AAU', 'DUP', 'AAE', 'RAIPEE'];
            const blueCodes = ['ICU', 'LDM', 'LOM', 'USE', 'LLI'];
            const orangeCodes = ['Contrac Bess', 'Easement EL'];

            if (greenCodes.includes(code)) return 'bg-green-lighter';
            if (blueCodes.includes(code)) return 'bg-blue-lighter';
            if (orangeCodes.includes(code)) return 'bg-orange-lighter';

            // Color por defecto si no está en ninguno de los grupos
            return 'bg-gray-200';
        },

        getWarningIcon(template) {
            const p = template.permitting;
            if (!p || !p.expiration_date) return '';

            // Ajusta formato si tus fechas vienen dd/mm/yy
            const expiration = moment(p.expiration_date, 'YYYY-MM-DD');
            if (!expiration.isValid()) return '';

            const diffDays = expiration.diff(moment(), 'days');
            if (diffDays < 0) {
                // Vencido => ícono rojo
                return `<i class="fas fa-exclamation-triangle text-red"></i>`;
            } else if (diffDays <= 3) {
                // 3 días o menos => ícono naranja
                return `<i class="fas fa-exclamation-triangle text-orange"></i>`;
            }
            return '';
        }
    },
};
</script>

<style lang="scss" scoped>

::v-deep .global-table th,
::v-deep .global-table td {
    text-align: center !important;
    vertical-align: middle !important;
}
::v-deep .global-table th,
::v-deep .global-table td {
    border: 0.5px solid black !important;
}
</style>