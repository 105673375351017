<template>
    <div>
        <div class="flex flex-row justify-end mb-2">
            <v-button @click="handleAddPermitting" class="mt-3" icon="add">
                {{ $t('add') }} {{ $t('permitting') }}
            </v-button>
        </div>
        <permittingsTable ref="permittings_table" :project-id="projectId" @edit-permitting="handleAddPermitting" />
        <permittingModal ref="permitting_modal" :project-id="projectId" :modal-ref="$refs.permitting_modal"
            @reload-list="handleSuccess" />
    </div>
</template>

<script>
import PermittingsTable from './permittings-table.vue';
import PermittingModal from './permitting-modal.vue';
import axios from 'axios';
export default {
    props: {
        projectId: {
            type: Number,
            required: true
        },
        company: {
            type: Number,
            required: true
        }
    },
    components: {
        PermittingsTable,
        PermittingModal
    },
    data() {
        return {
            activities: [],
            templates: [],
        }
    },
    mounted() {
        this.getActivities();
        this.getPermittingTemplate();
    },
    methods: {
        handleAddPermitting(permitting = null) {
            this.openPermittingModal(permitting)
        },
        openPermittingModal(permitting) {
            this.$refs.permitting_modal.show(permitting, this.activities, this.templates)
        },
        async getActivities() {
            try {
                const response = await axios.get(`/api/v2/activities`, {
                    params: {
                        p: this.projectId,
                        light: 1,
                        s: 1,
                        per_page: 999,
                        skip_map: 1
                    }
                });
                this.activities = response.data.activities.map(activity => ({
                    id: activity.id,
                    label: activity.name,
                    children: activity.subactivities ? activity.subactivities.map(sub => ({
                        id: sub.id,
                        label: sub.name,
                    })) : []
                }));
            } catch (error) {
                throw ("Error cargando actividades", error);
            }
        },


        async getPermittingTemplate() {
            try {
                const { data } = await axios.get(`/api/v2/company/${this.company}/permitting-templates`);
                this.templates = data.templates.data;
            } catch (error) {
                throw ('permittin', error);
            }
        },
        handleSuccess() {
            this.$refs.permittings_table.getPermittings()
        },

    },
}
</script>

<style lang="scss" scoped></style>