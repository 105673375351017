<template>
  <div>
    <!-- Si es Proyecto -->
    <div v-if="notification.project">
      <div class="flex items-center">
        <div class="flex-grow text-blue-light font-bold text-truncate cursor-pointer"
             @click="goToItem(notification, 'project')">
          <b-icon pack="fas" :icon="notification.read_at ? 'eye' : 'eye-slash'" class="text-blue ml-2"/>
          {{ notification.project.name }}
        </div>
        <div class="flex items-center">
                    <span :class="[isNew(notification.project) ? 'bg-red' : 'bg-orange']"
                          class="rounded-sm text-grey-lightest uppercase text-xs font-medium px-1 mr-2">
                        {{ getNotificationType(notification) }}
                    </span>
          <b-icon pack="fas" icon="lightbulb" size="is-small" class="text-blue"/>
        </div>
      </div>
    </div>

    <!-- Si es Flujo de Validacion -->
    <div v-else-if="notification.validation_flow">
      <div class="flex items-center">
        <div class="flex-grow text-blue-light font-bold text-truncate cursor-pointer"
             @click="goToItem(notification, 'validation')">
          <b-icon pack="fas" :icon="notification.read_at ? 'eye' : 'eye-slash'" class="text-blue ml-2"/>
          {{ notification.data.flow_name }}
          <p class="font-normal">{{ notification.data.flow_activity_name }}</p>
        </div>
        <div class="flex items-center">
                    <span :class="[isNew(notification.validation_flow) ? 'bg-red' : 'bg-orange']"
                          class="rounded-sm text-grey-lightest uppercase text-xs font-medium px-1 mr-2">
                        {{ getNotificationType(notification) }}
                    </span>
          <b-icon pack="fas" icon="lightbulb" size="is-small" class="text-blue"/>
        </div>
      </div>
    </div>

    <!-- Si es Actividad/Subactividad -->
    <div v-else-if="! notification.issue">
      <div class="flex items-center">
        <div class="flex-grow text-blue-light font-bold text-truncate cursor-pointer"
             @click="goToItem(notification, 'activity')">
          <b-icon pack="fas" :icon="notification.read_at ? 'eye' : 'eye-slash'" class="text-blue ml-2"/>
          {{ notification.activity.name }}
        </div>
        <div class="flex items-center">
                    <span :class="[isNew(notification.activity) ? 'bg-red' : 'bg-orange']"
                          class="rounded-sm text-grey-lightest uppercase text-xs font-medium px-1 mr-2">
                        {{ getNotificationType(notification) }}
                    </span>
          <b-icon pack="fas" icon="lightbulb" size="is-small" class="text-blue"/>
        </div>
      </div>
    </div>

    <!-- Si es Incidencia -->
    <div v-else>
      <div class="flex items-center">
        <div class="flex-grow text-red-light font-bold text-truncate cursor-pointer"
             @click="goToItem(notification, 'issue')">
          <b-icon pack="fas" :icon="notification.read_at ? 'eye' : 'eye-slash'" class="text-blue ml-2"/>
          {{ notification.issue.name }}
        </div>
        <div class="flex items-center">
                    <span class="bg-red rounded-sm text-grey-lightest uppercase text-xs font-medium px-1 mr-2">
                        {{ getNotificationType(notification) }}
                    </span>
          <b-icon pack="fas" icon="exclamation-triangle" size="is-small" class="text-red-light"/>
        </div>
      </div>
      <div class="flex-grow text-blue-light font-bold text-truncate cursor-pointer text-xs"
           @click="goToItem(notification, 'activity')">
        {{ notification.activity.name }}
      </div>
    </div>

    <!-- Si es Subactividad, mostrar enlace a la actividad padre -->
    <div v-if="notification.activity && notification.activity.parent"
         class="flex-grow text-blue-light font-bold text-truncate cursor-pointer text-xs"
         @click="goToItem(notification, 'parent_activity')">
      {{ notification.activity.parent.name }}
    </div>

    <!-- Tiempo -->
    <div>
      <l-timeago
          :since="notification.created_at"
          :locale="locale"
          class="text-xs text-grey font-semibold mb-2"
      />
    </div>

    <!-- Mensaje de notificatión -->
    <div class="mb-3">
      <b-icon pack="fa" class="float-left mr-1 mt-1" :icon="notification.data.notification_icon"
              size="is-small"></b-icon>
      <span class="font-raleway m-0 font-bold text-sm">{{ notification.data.notification_message }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "notification-panel-header",

  props: {notification: {type: Object, required: true}},

  computed: {
    ...mapGetters({
      project: 'app/project',
      locale: 'lang/locale',
    }),
  },

  methods: {

    goToItem(notification, type) {
      if (type === 'project') {
        this.$router.push({name: 'project.home', params: {project_id: notification.project.id}})
      } else if (type === 'validation') {
        this.$router.push({name: 'validation.home', params: {validation_id: notification.data.flow_id}})
      } else if (type === 'activity') {
        if (notification.activity.parent) {
          this.$router.push({
            name: 'subactivity.home',
            params: {subactivity_id: notification.activity.id, activity_id: notification.activity.parent.id}
          })
        } else {
          this.$router.push({name: 'activity.home', params: {activity_id: notification.activity.id}})
        }
      } else if (type === 'parent_activity') {
        this.$router.push({name: 'activity.home', params: {activity_id: notification.activity.parent.id}})
      } else if (type === 'issue') {
        if (notification.activity.parent) {
          this.$router.push({
            name: 'issue.home-sub',
            params: {
              issue_id: notification.issue.id,
              subactivity_id: notification.activity.id,
              activity_id: notification.activity.parent.id
            }
          })
        } else {
          this.$router.push({
            name: 'issue.home',
            params: {issue_id: notification.issue.id, activity_id: notification.activity.id}
          })
        }
      }
    },

    isNew(item) {
      return item.created_at === item.updated_at
    },

    newOrUpdatedText(item) {
      return this.$t(this.isNew(item) ? 'new_female' : 'updated_female')
    },

    getNotificationType(notification) {
      let tipos = {
        'App\\Notifications\\ActivityAssigned': 'activity',
        'App\\Notifications\\ActivityCommented': 'comment_single',
        'App\\Notifications\\ActivityCommentMention': 'comment_single',
        'App\\Notifications\\ActivityCompleted': 'activity',
        'App\\Notifications\\ActivityDeleted': 'activity',
        'App\\Notifications\\ActivityUpdated': 'activity',
        'App\\Notifications\\DocumentDeleted': 'document',
        'App\\Notifications\\DocumentUploaded': 'document',
        'App\\Notifications\\IssueAssigned': 'issue',
        'App\\Notifications\\IssueTaskAssigned': 'issue',
        'App\\Notifications\\IssueExpired': 'issue',
        'App\\Notifications\\IssueClosed': 'issue',
        'App\\Notifications\\IssueValidationFlowAssigned': 'issue',
        'App\\Notifications\\IssueCommented': 'comment_single',
        'App\\Notifications\\IssueCommentMention': 'comment_single',
        'App\\Notifications\\IssueDeleted': 'issue',
        'App\\Notifications\\IssueUpdated': 'issue',
        'App\\Notifications\\ProjectAssigned': 'project',
        'App\\Notifications\\UserUpdatedOnActivity': 'activity',
        'App\\Notifications\\UserUpdatedOnIssue': 'issue',
        'App\\Notifications\\UserUpdatedOnProject': 'project',
        'App\\Notifications\\NextValidationFlowTaskNotification': 'validation',
        'App\\Notifications\\RejectedValidationFlowTaskNotification': 'validation',
        'App\\Notifications\\ValidationFlowPending': 'validation',
        'App\\Notifications\\ValidationCommented': 'comment_single',
        'App\\Notifications\\ValidationCommentMention': 'comment_single'
      }

      try {
        return this.$t(tipos[notification.type]);
      } catch (e) {
        //Si falla es porque no existe el tipo, así que le damos uno genérico
        return this.newOrUpdatedText(notification.item);
      }
    }

  }
}
</script>

<style scoped>

</style>
