<template>
  <div class="flex-col">
    <b-tabs type="is-boxed" v-model="mainActiveTab">

      <b-tab-item>
        <template slot="header">
          <b-icon pack="fas" icon="tasks"></b-icon>
          <span> {{ project.name }} </span>
        </template>
        <div>

          <!-- <div class="flex justify-end">
            <div class="flex w-7/8">
              <b-tooltip :label="txtTooltipExport || this.$t('export')" position="is-left">
                <div class="rounded-full bg-blue w-8 h-8 flex ml-3 items-center justify-center text-grey-lightest cursor-pointer" @click="exportReport">
                  <b-icon icon="download" pack="fas" size="is-small" v-if="! isLoadingExport" />
                  <b-icon
                    pack="fas"
                    icon="sync-alt"
                    custom-class="fa-spin"
                    v-if="isLoadingExport"
                    >
                  </b-icon>
                </div>
              </b-tooltip>
            </div>
          </div> -->


          <!-- Seccion de Extrafields -->
          <div class="w-full panel">
            <div class="flex">
              <div class="flex flex-wrap w-full">
                <div v-for="extrafield in dataExtrafields" :key="'extrafield_' + extrafield.id" class="flex flex-col w-1/4">
                  <div class="w-full text-black font-bold">{{ extrafield.field.name }}</div>
                  <div class="w-full">{{ extrafield.value_free ? extrafield.value_free : '--' }}</div>
                </div>
                <div v-if="!extrafieldsFound" class="w-full text-center">No extrafields found</div>
              </div>
            </div>
          </div>


          <!-- Panel 2  BARRAS GRUPOS -->
          <div class="w-full mt-4 panel">
            <!-- <div class="flex flex-row w-full text-black font-bold justify-end text-xs"> -->
              <!-- <div>{{ $t('last_update') }}: {{ formatDate() }}</div>  -->
              <!-- Este panel no se cachea, mostrará hora actual -->
            <!-- </div> -->
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('current_progress') }}</div>
            <div class="flex flex-row w-full relative">
              <!--  <div v-if="isLoadingPanels" class="w-full relative text-center"> -->
                <b-loading :is-full-page="false" v-model="isLoadingPanels" :can-cancel="false"></b-loading>
              <!-- </div> -->
              <bar-chart :data="groupsChartsCombined" suffix="%" :min="0" :max="100" :stacked="false" :download="true" style="height: 500px;" :colors="['#3366CC', '#DC3913', '#129618']" />
            </div>
          </div>


          <!-- Barras Generales de progreso -->
          <div class="w-full mt-8 panel">
            <div style="width: 100%; text-align: center;">
              <div class="center">
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #95a3e0; display: inline-block"></div>
                  Total {{ $t('baseline') }}: {{ totalScheduled }}%
                </label>
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #ea9285; display: inline-block"></div>
                  Total {{ $t('inspected') }}: {{ totalProgress }}%
                </label>
                <label class="font-bold" style="font-size: 12px; color: #444">
                  <div style="height:10px; width: 40px; background: #76cf70; display: inline-block"></div>
                  Total {{ $t('actual') }}: {{ totalProgressReal }}%
                </label>
              </div>
              <div v-if="isLoadingPanels" class="">Loading...</div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{ 'width': totalScheduled + '%', 'background-color': '#95a3e0', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{'width': totalProgress + '%', 'background-color': '#ea9285', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
              <div style="width: 100%; background-color: #f3f3f3; border: 1px solid #ddd; border-radius: 5px; margin-top: 5px;">
                <div :style="{'width': totalProgressReal + '%', 'background-color': '#76cf70', 'height': 15+'px', 'border-radius': 5+'px'}"></div>
              </div>
            </div>
          </div>


          <!-- Tabla resumen progresos grupos -->
          <div class="w-full mt-8 panel relative">
            <b-table
              icon-pack="fas"
              :data="groupsTable"
              :bordered="true"
              :loading="isLoadingPanels"
              >
              <template>
                <b-table-column field="task" cell-class="w-5/12">
                  <template v-slot:header=""><span class="text-xs">{{ $t('task') }}</span></template>
                  <template v-slot="props">{{ props.row.name }}</template>
                </b-table-column>

                <b-table-column field="scheduled_start">
                  <template v-slot:header=""><span class="text-xs">{{ $t('scheduled_start_date') }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.current_start_date) }}</template>
                </b-table-column>

                <b-table-column field="scheduled_end">
                  <template v-slot:header=""><span class="text-xs">{{ $t('scheduled_end_date') }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.current_end_date) }}</template>
                </b-table-column>

                <b-table-column field="current_start">
                  <template v-slot:header=""><span class="text-xs">{{ $t('current_start_date') }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.real_start_date) }}</template>
                </b-table-column>

                <b-table-column field="current_end">
                  <template v-slot:header=""><span class="text-xs">{{ $t('current_end_date') }}</span></template>
                  <template v-slot="props">{{ formatDateCustom(props.row.real_end_date) }}</template>
                </b-table-column>

                <b-table-column field="avance">
                  <template v-slot:header=""><span class="text-xs">% {{ $t('progress_label') }}</span></template>
                  <template v-slot="props">{{ props.row.progress_real }}</template>
                </b-table-column>
              </template>

              <template #empty>
                  <div class="has-text-centered">{{ $t('no_results') }}</div>
              </template>
            </b-table>
          </div>

          <!-- Progreso general del proyecto -->
          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">% Project Progress</div>
            <div class="flex flex-row w-full">
              <div v-if="isLoadingProjectProgress" class="w-full relative flex flex-row text-center" style="height: 80px;">
                <b-loading :is-full-page="false" v-model="isLoadingProjectProgress" :can-cancel="false"></b-loading>
              </div>
              <line-chart v-else :data="projectProgress" :colors="['#3366CC', '#DC3913', '#129618']" suffix="%" />
            </div>
          </div>

          <!-- Cost control 1 -->
          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">Progress cost estimate</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costControl.cost1" suffix="€"/>
            </div>
          </div>

          <!-- Cost control 2 -->
          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('planned_sale') }} / {{ $t('real_sale') }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costControl.cost2" suffix="€"/>
            </div>
          </div>

          <!-- Cost control 3 -->
          <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('planned_cost') }} / {{ $t('real_cost') }} + {{ $t('forecast') }}</div>
            <div class="flex flex-row w-full">
              <line-chart :data="costControl.cost3" suffix="€"/>
            </div>
          </div>


          <!-- Panel 1 Progreso grupos -->
          <!-- <div class="panel w-full mt-8">
            <div class="flex flex-row w-full text-blue font-bold">{{ $t('group_progress') }}</div>
            <div class="flex flex-row w-full">
              <column-chart :data="panel1" suffix="%" :min="0" :max="100"/>
            </div>
          </div> -->


          <!-- Gráficas dinámicas por grupo -->
          <div class="w-full flex flex-row mt-8 panel" v-for="group in groupChartIndividual" :key="'charset_'+group.id">

            <!-- Grafica todos los meses (antiguo panel 3) -->
            <div class="w-3/4 flex flex-col">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('progress_of') }} {{group.name}}</div>
              <div v-if="!group.pendingLoad" class="flex flex-row w-full">
                <line-chart :suffix="'%'" :min="0" :max="100" :data="group.dataset" :colors="['#3366CC', '#DC3913', '#129618', '#fc8608']" />
              </div>
              <div v-else class="w-full relative flex flex-row text-center" style="height: 80px;">
                <b-loading :is-full-page="false" v-model="group.pendingLoad" :can-cancel="false"></b-loading>
              </div>
            </div>

            <!-- Grafica detalle mes actual -->
            <div class="w-1/4 flex flex-col">
              <div class="flex flex-row w-full text-blue font-bold">{{ $t('current_month') }}</div>
              <div v-if="!group.pendingLoad" class="flex flex-row w-full">
                <line-chart :suffix="'%'" :min="0" :max="100" :data="group.datasetCurrentMonth" :colors="['#DC3913', '#129618']" />
              </div>
              <div v-else class="w-full relative flex flex-row text-center" style="height: 80px;">
                <b-loading :is-full-page="false" v-model="group.pendingLoad" :can-cancel="false"></b-loading>
              </div>
            </div>

          </div>
        </div>
      </b-tab-item>

    </b-tabs>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
  import VueChartkick from 'vue-chartkick'
  import Chart from 'chart.js'
  import moment from 'moment'
  import excelByActivityGroups from '·/components/reports/excel-by-activity-groups.vue'

  Vue.use(VueChartkick, {adapter: Chart})
  Vue.use(moment)

  export default {
    middleware: 'auth',
    title: 'project_home_page_title',
    subtitle: 'project_home_page_subtitle',
    menuOption: '2-1',

    components: {
      ScaleLoader,
      excelByActivityGroups,
    },

    data: () => ({
      filters: {
        entity_id: 0,
        location_id: 0,
        status_id: 1,
        tag_name: '',
        parent_group_id: null
      },
      isLoading: true,
      panel1: {
        info: [],
        users: []
      },
      mainActiveTab: 0,
      options: [],

      isLoadingExport: false,
      txtTooltipExport: null,

      extrafieldsFound: true,

      // paneles
      dataExtrafields: [],
      groupsChartsCombined: [],
      totalScheduled: 0,
      totalProgress: 0,
      totalProgressReal: 0,
      groupsTable: [],
      groupChartIndividual: [],
      projectProgress: [],
      costControl: { cost1: {}, cost2: {}, cost3: {} },

      isLoadingPanels: true,
      isLoadingProjectProgress: true,
    }),

    computed: {
      ...mapGetters({
        project: 'app/project',
        user: 'auth/user',
      }),
    },

    mounted() {
      this.getDataExtrafields()

      if (this.project == null) {

        this.$router.push('/projects');

      } else {

        this.getPanels();
        // this.getProgressPerGroup(); // se decidió ocultar este panel
        this.getPanelsCostControl();
        // this.getProgressProject(); // anulado muy lento. se calcula en funcion de cada grupo

        let pageInfo = {
          title: this.project.name,
          subtitle: this.$t('project_home_page_subtitle'),
          menuOption: '2-1',
        }

        this.$store.dispatch('app/updatePageInfo', {info: pageInfo})
      }
    },

    methods: {

      async getDataExtrafields() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlextrafields';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.dataExtrafields = response.data.data
            self.extrafieldsFound = self.dataExtrafields.length > 0
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },

      // Para antiguo grafico de barras con progreso de cada grupo. (ocultado por ahora)
      // async getProgressPerGroup() {
      //   let self = this
      //   let url = '/api/v2/project/' + this.project.id + '/dashboard/wlgroupprogress';
      //   await axios.get(url, {params: this.filters})
      //     .then(response => {
      //       self.panel1 = response.data.data
      //     })
      //     .catch((error) => {
      //       self.$notify.error(error.error_msg || 'error_retrieving_data')
      //     });
      // },

      async getPanels() {
        let self = this
        self.isLoadingPanels = true;
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlpanels';

        await axios.get(url, {params: this.filters})
          .then(response => {
            self.groupsChartsCombined = response.data.data.groupsChartsCombined
            self.totalScheduled = response.data.data.totalScheduled
            self.totalProgress = response.data.data.totalProgress
            self.totalProgressReal = response.data.data.totalProgressReal
            self.groupsTable = response.data.data.groupsTable
            self.groupChartIndividual = response.data.data.groupChartIndividual
            self.isLoadingPanels = false

            // PARA TESTEAR //5385
            // self.groupChartIndividual = [
            //   { id: 806, name: "PERMITTING", dataset:	[], pendingLoad: true, },
            //   { id: 5692, name: "CONSTRUCTION", dataset:	[], pendingLoad: true, },
            // ]

            self.loadDatasetGroup()
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
      },


      loadDatasetGroup() {
        // filtramos los que faltar por cargar (pendingLoad == true)
        let groups = this.groupChartIndividual.filter(group => group.pendingLoad == true)
        if (groups.length > 0) {
          // de todos esos nos quedamos solo con el primero para ir llamando de 1 en 1
          this.filters.entity_id = groups[0].id
          this.getDatasetGroup(this.filters.entity_id)
        } else {
          // si no hay mas por cargar, terminamos
          // En getDatasetGroup se ha ido llamando para montar la grafica del proyecto
          // Un ultimo calculo a grafica proyecto. acumular avance de cada mes
          this.sumUpProjectProgress()
          // Grafica de progreso del proyecto cargada. 
          this.isLoadingProjectProgress = false;
        }
      },


      async getDatasetGroup(groupId) {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlgrouppanel';
        await axios.get(url, {params: this.filters})
          .then(response => {
            let data = response.data.data
            let g = self.groupChartIndividual.find(group => group.id == data.id )
            g.pendingLoad = false
            g.dataset = data.data
            g.datasetCurrentMonth = this.processDataCurrentMonth(data.dataCurrentMonth)

            this.calculateProjectProgress(data.weight, g.dataset)

            // si y solo si todo fue bien, llamamos al siguiente grupo
            setTimeout(() => {
              self.loadDatasetGroup()
            }, 500);

          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
            let g = self.groupChartIndividual.find(group => group.id == groupId )
            g.pendingLoad = false // quitamos el loading y mostramos 'Error loading'
            g.dataset = [{'name': 'Error loading', 'data': []}]
          })
          .finally(() => {
            // quito el setTimeout de aqui porque daba llamadas infinitas si algo fallaba
          });
      },


      // a partir de los datos de cada grupo, calculamos el progreso del proyecto (ahorramos calcular tooodo el proyecto)
      // recibe la ponderacion del grupo en el proyecto y el dataset del grupo.
      // Usa 'dataRaw' que tiene los avances en cada mes, si acumular. Se hará un acumulado de cada mes al final una vez recolectados toodos los datos, si se hace antes se altera el resultado
      calculateProjectProgress(weight, dataset) {

        try {
          let tmpDataset = dataset.filter(chartLine => (chartLine.name != 'Previsto' && chartLine.name != 'Forecast')) // en grafica conjunta de project no hay previsto
          tmpDataset.forEach(chartLine => {
            // console.log('Ponderacion '+weight+'. Procesando ', chartLine.name, " que tiene ", Object.keys(chartLine.data).length, " items")
            Object.keys(chartLine.dataRaw).forEach(month => {

              // console.log('procesando mes ', month, ' que tiene valor ', chartLine.data[month])

              // ese valor de ese mes representa el avance en el grupo. Lo multiplicamos por el peso del grupo en el proyecto
              let monthValueInProject = chartLine.dataRaw[month] * weight / 100

              // buscamos en grafico proyecto la linea del grafico que estamos procesando (linea base, inspeccionado, real, previsto )
              let projectChartLine = this.projectProgress.find(itemLine => itemLine.name == chartLine.name)
              if (projectChartLine == undefined) {
                this.projectProgress.push({name: chartLine.name, data: {}}) // si no encuentra la inserta
                projectChartLine = this.projectProgress.find(itemLine => itemLine.name == chartLine.name) // y la vueve a buscar para que el objeto apunte ahi
              }

              // ahora vemos si en esta linea (linea base o inspeccionado, etc) ya habia un valor para ese mes
              let projectMonthValue = Object.keys(projectChartLine.data).find(projectMonth => projectMonth == month)
              if (projectMonthValue == undefined) {
                projectChartLine.data[month] = monthValueInProject
              } else {
                projectChartLine.data[month] += monthValueInProject
              }
            })
          })
        } catch (error) {
          console.log('Error in calculateProjectProgress', error)
        }

      },


      // Acumula los valores de cada mes con los de los meses anteriores para dar progreso acumulado
      sumUpProjectProgress() {
        this.projectProgress.forEach(chartLine => {
          let accum = 0
          Object.keys(chartLine.data).forEach(month => {
            accum += chartLine.data[month]
            chartLine.data[month] = parseFloat(accum.toFixed(2))
          })
        })
      },


      // Recibe el dataset del mes actual y lo transforma para la grafica
      // separando en 2 lineas (Inspeccionado y Real)
      processDataCurrentMonth(dataCurrentMonth) {
        try {
          let data = [];
          let dataExecuted = {};
          let dataActual = {};

          Object.keys(dataCurrentMonth).forEach(day => {
            dataExecuted[day] = dataCurrentMonth[day].percent_executed_accum
            dataActual[day] = dataCurrentMonth[day].percent_executed_actual_accum
          })

          data.push({name: this.$t('inspected'), data: dataExecuted})
          data.push({name: this.$t('actual'), data: dataActual})

          return data;

        } catch (error) {
          console.log('Error in processDataCurrentMonth', error)
        }
      },


      // Anulado, muuuy lento. Se calcula en funcion de cada grupo
      async getProgressProject() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlprojectprogress';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.projectProgress = response.data.data
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });
        self.isLoadingProjectProgress = false;
      },


      async getPanelsCostControl() {
        let self = this
        let url = '/api/v2/project/' + this.project.id + '/dashboard/wlcostcontrol';
        await axios.get(url, {params: this.filters})
          .then(response => {
            self.isLoading = false;
            self.costControl.cost1 = response.data.data.cost1
            self.costControl.cost2 = response.data.data.cost2
            self.costControl.cost3 = response.data.data.cost3
          })
          .catch((error) => {
            self.$notify.error(error.error_msg || 'error_retrieving_data')
          });

      },


      formatDate(date) {
        return moment(date).format('HH:mm (DD-MM)'); //'DD-MM-YYYY HH:mm'
      },


      async exportReport() {

        if( !this.isLoadingExport ){
          this.txtTooltipExport = this.$t('generating');

          this.$notify.success('generating_report');
          this.isLoadingExport = true;
          let self = this
          let url = '/api/v2/project/' + this.project.id + '/dashboard/exportphotovoltaic';
          await axios.get(url, {params: this.filters})
            .then(response => {
              window.open(response.data.data);

              self.$notify.success('report_generated')
              self.isLoadingExport = false;
              self.txtTooltipExport = this.$t('export')
            })
            .catch((error) => {
              //Se produjo un error
              console.log("Error loading exportación")
              self.$notify.error(error.error_msg || 'error_retrieving_data')
              self.isLoadingExport = false
              self.txtTooltipExport = this.$t('export')
            });
        }
      },


      formatDateCustom(dateString) {
        if (!dateString) {
          return null;
        }
        // Split the input string by "-"
        const [year, month, day] = dateString.split("-");
        // Return the date in DD-MM-YYYY format
        return `${day}-${month}-${year}`;
      }
    },
  };
  </script>
