<template>
    <div class="panel">
        <b-table :data="permittings" :loading="isLoadingTable" paginated backend-pagination :per-page="perPage"
            :total="total" @page-change="onPageChange" backend-sorting :default-sort="orderField"
            :default-sort-direction="orderDir" @sort="onSort" class="w-full" striped bordered icon-pack="fas"
            scrollable>
            <template>
                <b-table-column field="id" :label="$t('id')" sortable v-slot="props">
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
                    {{ props.row.template.code }}
                </b-table-column>
                <b-table-column field="activity" :label="$t('activity')" sortable v-slot="props">
                    {{ props.row.activity.name }}
                </b-table-column>
                <b-table-column field="provider" :label="$t('request_date')" v-slot="props">
                    {{ formatDate(props.row.request_date) }}
                </b-table-column>
                <b-table-column field="provider" :label="$t('obtention_date')" v-slot="props">
                    {{ formatDate(props.row.obtention_date) }}
                </b-table-column>
                <b-table-column field="provider" :label="$t('expiration_date')" v-slot="props">
                    {{ formatDate(props.row.expiration_date) }}
                </b-table-column>
                <b-table-column field="actions" :label="$t('actions')" v-slot="props" centered>
                    <div class="w-full h-full flex items-center justify-around">
                        <b-tooltip :label="$t('edit')" position="is-left">
                            <div @click="editClicked(props.row)" class="cursor-pointer flex text-blue">
                                <b-icon pack="fas" icon="pencil-alt" />
                            </div>
                        </b-tooltip>
                        <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                            <div @click="removeClicked(props.row)" class="cursor-pointer flex text-red">
                                <b-icon pack="fas" icon="trash-alt" />
                            </div>
                        </b-tooltip>
                    </div>
                </b-table-column>
            </template>
            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>
        </b-table>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    props: {
        projectId: { type: Number, require: true },
    },
    data() {
        return {
            isLoadingTable: true,
            permittings: [],
            perPage: 25,
            orderField: 'permitting_template_id',
            orderDir: 'asc',
            page: 1,
            last: 1,
            total: 0,
        }
    },
    created () {
        this.getPermittings();
    },
    computed: {
        filters() {
            let filters = {
                'order_field': this.orderField,
                'order_dir': this.orderDir,
                'per_page': this.perPage,
                'page': this.page,
                'project_id': this.projectId
            }
            return filters
        },
    },
    methods: {
        async getPermittings(filters = null) {
            this.isLoadingTable = true;
            const params = filters ? filters : this.filters;

            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/permittings`, { params });
                if (data) {
                    this.permittings = data.data
                    this.last = data.last_page;
                    this.total = data.total;
                }
            } catch (error) {
                throw ('Error fetching permittings:', error);
            } finally {
                this.isLoadingTable = false;
            }
        },
        onSort(field, order) {
            this.orderField = field;
            this.orderDir = order;
            this.getPermittings(this.filters);
        },

        onPageChange(page) {
            this.page = page;
            this.getPermittings();
        },
        editClicked(row) {
            this.$emit('edit-permitting', row);
        },
        async removeClicked(row) {
            try {
                this.$dialog.confirm({
                    title: this.$t('delete'),
                    message: this.$t('delete_confirm_text', [row.template.code]),
                    confirmText: this.$t('delete'),
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: async () => {
                        const url = `/api/v2/project/${this.projectId}/permitting/${row.id}`;
                        await axios.delete(url);
                        this.$buefy.toast.open({
                            message: this.$t('delete_successful'),
                            type: 'is-success'
                        });
                        this.getPermittings();
                    },
                });
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t('delete_error'),
                    type: 'is-danger'
                });
            }
        },
        formatDate(date) {
            return date ? moment(date).format('DD/MM/YYYY') : '';
        }
    },

}
</script>

<style lang="scss" scoped></style>